import React, { useState, useEffect } from "react";
import ReactTable from "react-table-6";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import ViewTransactionModal from "./viewTransactionModal";

import "react-table-6/react-table.css";
import { withRouter } from "react-router";
import { transactionList, showTransactionDetails } from "../../apis/index";
import { getPagination } from "../../helper/constant";
import moment from "moment-timezone";
let debounceTimer;

const TransactionTable = (props) => {
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pages, setPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [params, setParams] = useState({
    isExport: false,
    orderBy: "id",
    orderSequence: "DESC",
    pageNo: 1,
    pageSize: 25,
    search: "",
  });
  const [transactionDetails, setTransactionDetails] = useState({});
  const [modal, setModal] = useState(false);
  const [DocModal, setDocModal] = useState(false);
  const [doc, setDoc] = useState({
    id: 0,
    list: [],
  });

  const toggle = () => setModal(!modal);

  const {
    sidebarTheme: { activeColor, textColor },
  } = props;

  useEffect(() => {
    if (searchText !== null) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
      debounceTimer = setTimeout(callSearchApi, 500);
    }
  }, [searchText]);

  useEffect(() => {
    transactionList(params).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = getPagination(res.data.totalRecords, params.pageSize);
        setPages(pages);
      }
    });
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
    };
  }, []);

  const convertArrayOfObjectsToCSV = (args) => {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data;
    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = ",";
    lineDelimiter = "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item) => {
      ctr = 0;
      keys.forEach(function(key) {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });
    return result;
  };

  const exportToCSV = () => {
    transactionList({
      isExport: true,
      orderBy: "id",
      orderSequence: "DESC",
      pageNo: 1,
      pageSize: 25,
      search: "",
    })
      .then((res) => {
        if (res && res.status) {
          var data, filename, link;

          var csv = convertArrayOfObjectsToCSV({
            data: res.data.list,
          });

          if (csv == null) return;

          filename = "transactions.csv";

          if (!csv.match(/^data:text\/csv/i)) {
            csv = "data:text/csv;charset=utf-8," + csv;
          }
          data = encodeURI(csv);

          link = document.createElement("a");
          link.setAttribute("href", data);
          link.setAttribute("download", filename);
          link.click();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onPageChangeHandler = (index) => {
    let page = index + 1;
    setActivePage(page);
    let data = {
      ...params,
      pageNo: page,
    };
    setParams(data);
    setLoader(true);
    transactionList(data).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = getPagination(res.data.totalRecords, params.pageSize);
        setPages(pages);
      }
    });
  };

  const callSearchApi = () => {
    const data = {
      ...params,
      search: searchText,
    };
    setParams(data);
    setLoader(true);
    transactionList(data).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = getPagination(res.data.totalRecords, params.pageSize);
        setPages(pages);
      }
    });
  };

  const showOneTransaction = (data) => {
    showTransactionDetails(data).then((res) => {
      if (res && res.status) {
        setTransactionDetails(res.data);
        setModal(true);
      } else {
      }
    });
  };

  const onSortChange = (sortProperties) => {
    const orderByDirection = sortProperties[0].desc ? 1 : 0;
    if (!orderByDirection) {
      let data = {
        ...params,
        orderBy: sortProperties[0].id,
        orderSequence: "DESC",
      };
      setParams(data);
      setLoader(true);
      transactionList(data).then((res) => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
          const pages = getPagination(res.data.totalRecords, params.pageSize);
          setPages(pages);
        }
      });
    } else {
      let data = {
        ...params,
        orderSequence: "ASC",
      };
      setParams(data);
      setLoader(true);
      transactionList(data).then((res) => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
          const pages = getPagination(res.data.totalRecords, params.pageSize);
          setPages(pages);
        }
      });
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "User",
        accessor: "user",
        className: "text-center",
        style: { whiteSpace: "pre-wrap", textTransform: "capitalize" },
        filterable: false,
        sortable: false,
        headerClassName: "react-table-header-class",
        Cell: (props) => {
          return props["original"]["user.fullName"];
        },
      },
      {
        Header: "Order Number",
        accessor: "orderNo",
        className: "text-center",
        filterable: false,
        sortable: true,
        headerClassName: "react-table-header-class",
      },
      {
        Header: "Order Date",
        accessor: "orderDate",
        className: "text-center",
        filterable: false,
        sortable: false,
        headerClassName: "react-table-header-class",
        width: 120,
        Cell: (props) => {
          if (props && props.original && props.original.createdAt) {
            return moment(props.original.createdAt)
              .tz("Australia/Perth")
              .format("DD/MM/YYYY");
          }
          return "-";
        },
      },
      {
        Header: "Payment Status",
        accessor: "paymentStatus",
        className: "text-center",
        filterable: false,
        sortable: true,
        headerClassName: "react-table-header-class",
      },
      {
        Header: "Order Cancel",
        accessor: "isCanceled",
        className: "text-center",
        filterable: false,
        sortable: false,
        headerClassName: "react-table-header-class",
      },
      {
        Header: "Bar Name",
        accessor: "bar",
        className: "text-center",
        filterable: false,
        style: { whiteSpace: "pre-line" },
        sortable: false,
        headerClassName: "react-table-header-class",
        Cell: (props) => {
          return props["original"]["bar.restaurantName"];
        },
      },
      {
        Header: "Order Status",
        accessor: "orderStatus",
        className: "text-center",
        filterable: false,
        sortable: false,
        headerClassName: "react-table-header-class",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        filterable: false,
        width: 150,
        Cell: (props) => (
          <div className="react-action-class">
            <Button
              className="c-btn c-success mr-10"
              onClick={() => showOneTransaction(props.original.order_id)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-eye mr-6" /> View
              </div>
            </Button>
          </div>
        ),
      },
      {
        Header: "Date",
        accessor: "createdAt",
        className: "text-center",
        filterable: false,
        sortable: true,
        width: 100,
        headerClassName: "react-table-header-class -sort",
        Cell: (props) => {
          if (props && props.original && props.original.createdAt) {
            return moment(props.original.createdAt)
              .tz("Australia/Perth")
              .format("DD/MM/YYYY");
          }
          return "-";
        },
      },
    ],
    [params]
  );

  return (
    <React.Fragment>
      <ViewTransactionModal
        transactionDetails={transactionDetails}
        toggle={toggle}
        modal={modal}
      />
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Transactions
            </div>
            <div className="mr-10">
              <input
                value={searchText ? searchText : ""}
                onChange={(e) => setSearchText(e.target.value)}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            {/* <div>
                            <Button
                                className="c-btn ma-5"
                                onClick={() => formAction("add")}
                                style={{
                                    backgroundColor: activeColor,
                                    color: textColor
                                }}>
                                <i className="fas fa-plus mr-10"></i>Add Bar
                            </Button>
                        </div> */}
            <div>
              <Button
                className="c-btn ma-5 c-warning"
                onClick={() => exportToCSV()}
                style={{
                  backgroundColor: activeColor,
                  color: textColor,
                }}
              >
                <i className="fas fa-file-export mr-10"></i>Export
              </Button>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={2}
              minRows={0}
              pages={pages}
              filterable
              manual
              className="-striped -highlight custom-react-table-theme-class"
              page={activePage - 1}
              PaginationComponent={Pagination}
              loading={loader}
              onPageChange={(pageIndex) => onPageChangeHandler(pageIndex)}
              onSortedChange={(sortProperties) => onSortChange(sortProperties)}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(TransactionTable);
