import React from "react";
import UserWrapper from "../../assets/styledComponents/user.style";
import PageTitle from "components/common/PageTitle";
import UserOrderTable from "../../components/users/UserOrderTable";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const Orders = (props) => {
	const history = useHistory();
	return (
		<UserWrapper {...props}>
			<div className="pos-relative">
				<PageTitle title="User" className="plr-15" iconClass="fas fa-user" />
				<div
					className="back-icon fs-15 demi-bold-text cursor-pointer"
					onClick={() => history.goBack()}
				>
					<i className="fas fa-step-backward"></i> Backward
				</div>
			</div>
			<div className="user-tbl">
				<UserOrderTable {...props} />
			</div>
		</UserWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.themeChanger,
	};
};

export default connect(mapStateToProps, null)(Orders);
