import React, { useEffect, useState } from "react";
import UserWrapper from "../../../assets/styledComponents/user.style";
import PageTitle from "components/common/PageTitle";
import TemplateForm from "../../../components/template/Email/TemplateForm";
import CustomToast from "../../../components/customToast/CustomToast";
import { editTemplate } from "../../../apis/index";

import { connect } from "react-redux";

// const REDIRECT_URL = process.env.REACT_APP_LOCAL_CMS_URL+'template/email';
const REDIRECT_URL = process.env.REACT_APP_STAGING_CMS_URL+'template/email';

const TemplateEdit = props => {
  const [editedData, setEditedData] = useState(null);
  const [toast, showToast] = useState(false);
  const [toastMsg, setMsg] = useState("");
  const hasParam = props.match.params.hasOwnProperty("id");

  useEffect(() => {
    if (hasParam) {
      const data = {
        ...props.location.state
      };
      // console.log(data);
      setEditedData(data);
    }
  }, []);

  const submitFormHandler = (data, action) => {
    if (action === "edit") {
      let apiData = {
        ...data,
        id: props.location.state.id,
      };
      editTemplate(apiData).then(res => {
        if (res && res.status) {
          // props.history.push("/template/email");
          window.location.href = `${REDIRECT_URL}`;
        } else {
          if (res) {
            setMsg(res.message);
            showToast(true);
          }
        }
      });
    }
  };


  return (
    <UserWrapper {...props}>
      {toast && (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className="c-danger"
          message={toastMsg}
          onCloseCLick={() => showToast(false)}
        />
      )}
      <div className="pos-relative">
        <PageTitle
          title="Template"
          className="plr-15"
          iconClass="fas fa-paint-brush"
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => 
            // props.history.push("/template/email")
            window.location.href = `${REDIRECT_URL}`
          }
        >
          <i className="fas fa-step-backward"></i> Backward
                </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span>{" "}
              {hasParam ? "Edit" : "Add"} Template
                        </div>
          </div>
          <div className="roe-card-body">
            <TemplateForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              action={hasParam ? "edit" : "add"}
            />
          </div>
        </div>
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(
  mapStateToProps,
  null
)(TemplateEdit);
