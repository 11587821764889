import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    restaurantName: Yup.string().required("This field is required"),
    managerName: Yup.string().required("This field is required"),
    transactionFee: Yup.string().required("This field is required"),
    address: Yup.string().required("This field is required"),
    mobile: Yup.number().min(8, "must be 8 digits or more").required("This field is required"),
    longitude: Yup.string().required("This field is required"),
    latitude: Yup.string().required("This field is required"),
    email: Yup.string()
      .required("This Field is required")
      .email("Please Enter Valid Email"),
  }),

  // isInitialValid: true,
  mapPropsToValues: props => ({
    restaurantName: props.editedData ? props.editedData.restaurantName : "",
    email: props.editedData ? props.editedData.email : "",
    address: props.editedData ? props.editedData.address : "",
    managerName: props.editedData ? props.editedData.managerName : "",
    transactionFee: props.editedData && props.editedData.setting? props.editedData.setting.paramVal : "",
    longitude: props.editedData ? props.editedData.longitude : "",
    latitude: props.editedData ? props.editedData.latitude : "",
    mobile: props.editedData ? props.editedData.mobile : "",
    avatar: props.editedData ? props.editedData.avatar : ""
  }),
  handleSubmit: values => { },
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
