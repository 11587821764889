import React, { Component } from "react";
import Sidebar from "components/sidebar/Sidebar";
import Radium from "radium";
import dashboardRoutes from "routes/dashboardRoutes";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import themeActions from "redux/themeChanger/actions.js";
import settingactions from "redux/themeSettings/actions";
import AppLocale from "languageProvider";
import {
    drawerWidth,
    miniDrawerWidth
} from "helper/constant";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { ProtectedRoute } from "./../routes/ProtectedRoute";
import { Scrollbars } from "react-custom-scrollbars";
import GlobalWrapper from "./global.style";
const { changeTheme } = themeActions;
const { sidebarMini } = settingactions;

class DashboardLayout extends Component {
    state = {
        mini: this.props.themeSetting.sidebarMiniValue
            ? this.props.themeSetting.sidebarMiniValue === "on"
            : false,
        themeDrawer: true,
        statedrawerWidth: drawerWidth,
        stateminiDrawerWidth: miniDrawerWidth
    };

    componentDidUpdate = prevProps => {
        if (this.props.location !== prevProps.location) {
            const { scrollbars } = this.refs;
            scrollbars.scrollTop(0);
        }
    };

    componentWillReceiveProps = nextprops => {
        if (
            this.props.themeSetting.sidebarMiniValue !==
            nextprops.themeSetting.sidebarMiniValue
        ) {
            this.setState({
                mini: nextprops.themeSetting.sidebarMiniValue === "on"
            });
        }
    };

    drawerMiniMethod = () => {
        if (this.state.mini) {
            this.setState({ mini: false });
            this.props.sidebarMini("off");
        } else {
            this.setState({ mini: true });
            this.props.sidebarMini("on");
        }
    };

    themeSettingDrawer = () => {
        if (this.state.themeDrawer) {
            this.setState({ themeDrawer: false });
        } else {
            this.setState({ themeDrawer: true });
        }
    };

    mouseEnter = () => {
        if (this.state.mini) {
            this.setState({ stateminiDrawerWidth: drawerWidth });
        }
    };

    mouseLeave = () => {
        if (this.state.mini) {
            this.setState({ stateminiDrawerWidth: miniDrawerWidth });
        }
    };

    closeDrawer = () => {
        this.setState({ mini: true });
    };

    render() {
        let routeScrollHeight;
        const {
            mini,
            statedrawerWidth,
            stateminiDrawerWidth
        } = this.state;
        const { layoutTheme, locale, themeSetting } = this.props;
        const currentAppLocale = AppLocale[locale];

        let routeArrowIcon = {
            backgroundColor: this.props.sidebarTheme.backgroundColor,
            color: this.props.sidebarTheme.textColor,
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            position: "fixed",
            zIndex: 1,
            fontSize: "18px",
            padding: "4px 5px"
        };

        if (
            themeSetting.toolbarDisplayValue === "hide" &&
            themeSetting.footerDisplayValue === "hide"
        ) {
            routeScrollHeight = {
                height: "calc(100vh - 0px)"
            };
        } else if (themeSetting.toolbarDisplayValue === "hide") {
            routeScrollHeight = {
                height: "calc(100vh - 51px)"
            };
        } else if (themeSetting.footerDisplayValue === "hide") {
            routeScrollHeight = {
                height: "calc(100vh - 65px)"
            };
        } else {
            routeScrollHeight = {
                height: "calc(100vh - 116px)"
            };
        }

        const mainPanelWidth = {
            width: mini
                ? `calc(100% - ${miniDrawerWidth})`
                : `calc(100% - ${drawerWidth})`,
            "@media (max-width: 767.98px)": {
                width: `100%`
            }
        };

        return (
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
            >
                <GlobalWrapper {...this.props}>
                    <Sidebar
                        mini={mini}
                        drawerWidth={statedrawerWidth}
                        miniDrawerWidth={stateminiDrawerWidth}
                        onMouseEnter={this.mouseEnter}
                        onMouseLeave={this.mouseLeave}
                        closeDrawer={() => this.closeDrawer}
                        {...this.props}
                    />

                    <div
                        id="main-panel"
                        className="main-panel"
                        ref="mainPanel"
                        style={mainPanelWidth}
                    >
                        {this.props.themeSetting.toolbarDisplayValue ===
                            "show" && (
                                <Header
                                    drawerMiniMethod={() => this.drawerMiniMethod}
                                    mini={mini}
                                    drawerWidth={statedrawerWidth}
                                    miniDrawerWidth={stateminiDrawerWidth}
                                    {...this.props}
                                />
                            )}

                        <div className="drawer-handle-arrow-hide-topbar">
                            {themeSetting.toolbarDisplayValue !== "show" ? (
                                mini ? (
                                    <button
                                        style={routeArrowIcon}
                                        className="c-btn"
                                        onClick={this.drawerMiniMethod}
                                    >
                                        <i className="fas fa-arrow-right" />
                                    </button>
                                ) : (
                                        <button
                                            style={routeArrowIcon}
                                            className="c-btn"
                                            onClick={this.drawerMiniMethod}
                                        >
                                            <i className="fas fa-arrow-left" />
                                        </button>
                                    )
                            ) : (
                                    ""
                                )}
                        </div>

                        {themeSetting.toolbarDisplayValue !== "show" && (
                            <button
                                style={routeArrowIcon}
                                className="c-btn mini-drawer-menu-icon-hide-topbar"
                                onClick={this.drawerMiniMethod}
                            >
                                <i className="fas fa-bars" />
                            </button>
                        )}

                        <Scrollbars
                            style={routeScrollHeight}
                            autoHide
                            ref="scrollbars"
                        >
                            {/* Route Layout Start*/}
                            <div
                                className="route-height"
                                style={
                                    themeSetting.toolbarDisplayValue === "show"
                                        ? {
                                            background:
                                                layoutTheme.backgroundColor
                                        }
                                        : {
                                            background:
                                                layoutTheme.backgroundColor
                                        }
                                }
                            >
                                <Switch>
                                    <ProtectedRoute {...this.props}>
                                        {dashboardRoutes.map((prop, key) => {
                                            return (
                                                <Route
                                                    exact
                                                    path={prop.path}
                                                    component={prop.component}
                                                    key={key}
                                                />
                                            );
                                        })}
                                    </ProtectedRoute>
                                </Switch>
                            </div>
                            {/* Route Layout Finish*/}
                        </Scrollbars>
                        {themeSetting.footerDisplayValue === "show" && (
                            <Footer
                                {...this.props}
                                mini={mini}
                                drawerWidth={statedrawerWidth}
                                miniDrawerWidth={stateminiDrawerWidth}
                            />
                        )}
                    </div>
                </GlobalWrapper>
            </IntlProvider>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.themeChanger,
        LanguageSwitcher: state.LanguageSwitcher,
        locale: state.LanguageSwitcher.language.locale,
        authData: {
            token: state.auth.accessToken,
            isLogin: state.auth.isLogin
        },
        themeSetting: {
            toolbarAlignValue: state.themeSetting.toolbarAlignValue,
            footerAlignValue: state.themeSetting.footerAlignValue,
            sidebarDisplayValue: state.themeSetting.sidebarDisplayValue,
            toolbarDisplayValue: state.themeSetting.toolbarDisplayValue,
            footerDisplayValue: state.themeSetting.footerDisplayValue,
            sidebarTransParentValue: state.themeSetting.sidebarTransParentValue,
            transparentImage: state.themeSetting.transparentImage,
            activeLinkStyle: state.themeSetting.activeLinkStyle,
            sidebarMiniValue: state.themeSetting.sidebarMiniValue,
            sidebarTransParentActiveBack:
                state.themeSetting.sidebarTransParentActiveBack,
            sidebarTransParentActiveColor:
                state.themeSetting.sidebarTransParentActiveColor
        }
    };
};

export default connect(
    mapStateToProps,
    {
        changeTheme,
        sidebarMini
    }
)(Radium(DashboardLayout));
