import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    // code: Yup.string().required("This field is required").matches(
    //         /^([A-Z0-9]+)$/,
    //         "Allow alphanumeric"
    //       ),
    title: Yup.string().required("This field is required"),
    json: Yup.string().required("This field is required"),
    //description: Yup.string().required("This field is required"),
    // discount_amount: Yup.number().required("This field is required"),
    // startsAt: Yup.string().required("This field is required"),
    // expiresAt: Yup.string().required("This field is required")
  }),

  // isInitialValid: true,
  mapPropsToValues: props => ({
    type: "email",
    title: props.editedData ? props.editedData.title : "",
    json: props.editedData ? props.editedData.json : "",
    // content: props.editedData ? props.editedData.content : "",
    // description: props.editedData ? props.editedData.description : "",
  }),
  handleSubmit: values => { },
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
