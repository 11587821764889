import React from "react";
import StyleWrapper from "../../../assets/styledComponents/user.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import Menus from "./Menus";

const User = props => {
  return (
    <StyleWrapper {...props}>
      <PageTitle title="Menus" className="plr-15" iconClass="fas fa-list" />
      <div className="user-tbl">
        <Menus {...props} />
      </div>
    </StyleWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(User);
