import React, { useState, Fragment, useEffect } from "react";
import enhancer from "./validator";
import CustomToast from "../customToast/CustomToast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CKEditor from "ckeditor4-react";
import { ckConfig } from "../../helper/constant";

import {
    checkCodeExist
} from "../../apis/index";

const PromoCodeForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
    setFieldValue
  } = props;

  const [promoCodeData, setPromoCodeData] = useState(null);
  const [editorState, setEditorState] = useState(null);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(startDate);

  const handleChangeDate = date =>  {
    setFieldValue('startsAt', date);
    setStartDate(date);
    setEndDate(date);
  }

  const handleChangeEndDate = date =>  {
    setFieldValue('expiresAt', date);
    setEndDate(date);
  }

  const checkCode = code => {
    // let data = {
    //   code: code.target.value
    // }
    // checkCodeExist(data).then(res => {
    //         if(res.status == 1) {
    //           console.log("errors", errors);
    //         }
    //     });
  }
  useEffect(() => {
    if(values.startsAt) {
      setStartDate(new Date(values.startsAt));
    }
    if(values.expiresAt) {
      setEndDate(new Date(values.expiresAt));
    }
    // if(values.description) {
    //   setEditorState(values.description)
    // }
  },[values.startsAt, values.expiresAt, values.description]);


  const [toast, showToast] = useState(false);
  
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleSubmit = () => {
    props.handleSubmit();
    console.log("props", props);
    
    if (action === "edit") {
      const err = Object.getOwnPropertyNames(props.errors);
      if (props.isValid || !err.length) {
        let { values } = props;
        const submitData = {
          ...values,
          description: values.description
        };
        onSubmit(submitData, action);
      }
    }
    if (action === "add") {
      if (props.isValid) {

        let { values } = props;
        const subObj = {
          ...values,
          description: values.description
        };
        onSubmit(subObj, action);
      }
    }
  };
  
  return (
        <Fragment>
            {toast && (
                <CustomToast
                    heading={"Alert!"}
                    width={400}
                    show="bottomRight"
                    transition
                    position="bottom-right"
                    className="c-danger"
                    message="You haven't changed Anything"
                    onCloseCLick={() => showToast(false)}
                />
            )}

            <form>
                <div className="form-group">
                    <label className="fs-16 medium-text">Code<span className="c-text-danger">*</span></label>
                    <input
                        type="text"
                        className="form-control react-form-input"
                        id="code"
                        style={{textTransform: "uppercase"}}
                        onChange={handleChange}
                        //onBlur={handleBlur}
                        value={values.code ? values.code.toUpperCase(): values.code}
                        placeholder="Code"
                        onBlur={e => checkCode(e)}
                    />
                    <Error field="code" />
                </div>

                <div className="form-group">
                    <label className="fs-16 medium-text">Name<span className="c-text-danger">*</span></label>
                    <input
                        type="text"
                        className="form-control react-form-input"
                        id="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        placeholder="Name"
                    />
                    <Error field="name" />
                </div>
                
                <div className="form-group">
                    <label className="fs-16 medium-text">Discount(%)<span className="c-text-danger">*</span></label>
                    <input
                        type="text"
                        className="form-control react-form-input"
                        id="discount_amount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.discount_amount}
                        placeholder="Discount"
                    />
                    <Error field="discount_amount" />
                </div>

                <div className="form-group">
                    <label className="fs-16 medium-text">Start Date<span className="c-text-danger">*</span></label>
                    <DatePicker
                        placeholderText="Start Date"
                        selected={startDate}
                        value={startDate}
                        onChange={handleChangeDate}
                        id="startsAt"
                        style={{width:"100%", display:"block"}}
                        className="custom-datepicker form-control react-form-input"
                    />
                    <Error field="startsAt" />
                </div>
                <div className="form-group">
                    <label className="fs-16 medium-text">Expires Date<span className="c-text-danger">*</span></label>
                    <DatePicker
                        placeholderText="Expires Date"
                        selected={endDate}
                        onChange={handleChangeEndDate}
                        id="expiresAt"
                        onBlur={handleBlur}
                        className="custom-datepicker form-control react-form-input"
                    />
                    <Error field="expiresAt" />
                </div>
                <div className="form-group">
                  <label className="fs-16 medium-text">Description</label>
                  {console.log("editorState", editorState)}
                  {/* <CKEditor
                    id="description"
                    onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
                    config={ckConfig}
                    data={editorState}
                    onChange={event => {
                      setEditorState(event.editor.getData());
                    }}
                  /> */}
                  <textarea
                    className="form-control react-form-input"
                    id="description"
                    value={values.description}
                    onChange={handleChange}    
                    rows="3">
                  </textarea>
                </div>
                <div>
                    <button
                        type="button"
                        className="c-btn c-info form-button fs-16 demi-bold-text"
                        style={{ maxWidth: "125px" }}
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </Fragment>
    );

};

export default enhancer(PromoCodeForm);
