export const sidebarData = [
  {
    name: 'Dashboard',
    iconClass: 'fas fa-tachometer-alt',
    routepath: '/dashboard',
    // child: [
    //   {
    //     listname: 'sidebar.lists',
    //     routepath: '/users',
    //     shortname: 'LI'
    //   }
    // ]
  },
  {
    name: 'User Management',
    iconClass: 'fas fa-user',
    routepath: '/users',
    // child: [
    //   {
    //     listname: 'sidebar.lists',
    //     routepath: '/users',
    //     shortname: 'LI'
    //   }
    // ]
  },
  {
    name: 'Venue Management',
    iconClass: 'fas fa-glass-martini',
    routepath: '/bars',
    // child: [
    //   {
    //     listname: 'sidebar.lists',
    //     routepath: '/users',
    //     shortname: 'LI'
    //   }
    // ]
  },
  {
    name: 'Category Management',
    iconClass: 'fas fa-list',
    routepath: '/categories'
  },
  {
    name: 'Promo Code Management',
    iconClass: 'fas fa-tags',
    routepath: '/promo-code'
  },
  {
    name: 'Template Management',
    // routepath: '/template',
    iconClass: 'fas fa-paint-brush',
    child: [
      {
        listname: 'Email',
        routepath: '/template/email',
        shortname: "fas fa-envelope",
      },
      {
        listname: 'Notification',
        routepath: '/template/notification',
        shortname: "fas fa-comment",
      }
    ]
  },
  {
    name: 'Pages Management',
    iconClass: 'fas fa-file-alt',
    routepath: '/pages',
    // child: [
    //   {
    //     listname: 'sidebar.lists',
    //     routepath: '/users',
    //     shortname: 'LI'
    //   }
    // ]
  },
  {
    name: 'Order Management',
    iconClass: 'fas fa-utensils',
    routepath: '/venue-orders'
  },
  {
    name: 'Transaction Management',
    iconClass: 'fas fa-money-check-alt',
    routepath: '/transactions'
  },
  // {
  //   name: 'Payment Management',
  //   iconClass: 'fas fa-money-check',
  //   routepath: '/payment',
  //   // child: [
  //   //   {
  //   //     listname: 'sidebar.lists',
  //   //     routepath: '/users',
  //   //     shortname: 'LI'
  //   //   }
  //   // ]
  // }
];

// Comments:::::::

//  If you want one level child then look below example

/*
  {
    name: 'sidebar.forms',
    iconClass: 'fab fa-wpforms',
    child: [
      {
        listname: 'sidebar.regularforms',
        routepath: '/regularform',
        shortname: 'RF'
      }
    ]
  }
*/

//  If you want Second level child then look below example

/*
   {
      name: 'sidebar.pages',
      iconClass: 'fas fa-print',
      child: [
        {
          listname: 'sidebar.authentication',
          iconClass: 'fas fa-user',
          child: [
            {
              listname: 'sidebar.login',
              routepath: '/login',
              shortname: 'L'
            },
          ]
        }
      ]
    }
*/
