import React from "react";
import PageTitle from "../../components/common/PageTitle";
import DashboardCards from '../../components/Dashboard/dashboard'

const Dashboard = props => {
  return (
    <React.Fragment>
      <PageTitle
        iconClass="fas fa-tachometer-alt"
        title="Dashboard"
        className="plr-15"
      />
      <DashboardCards />
    </React.Fragment>
  );
};



export default Dashboard;
