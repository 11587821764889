import React from "react";
import StyleWrapper from "../../assets/styledComponents/user.style";
import PageTitle from "components/common/PageTitle";
import VenueTable from "../../components/orders/VenueTable";
import { connect } from "react-redux";

const VenueOrder = (props) => {
	return (
		<StyleWrapper {...props}>
			<PageTitle
				title="Orders"
				className="plr-15"
				iconClass="fas fa-utensils"
			/>
			<div className="user-tbl">
				<VenueTable {...props} />
			</div>
		</StyleWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.themeChanger,
	};
};

export default connect(mapStateToProps, null)(VenueOrder);
