import React from "react";
import StyleWrapper from "../../assets/styledComponents/user.style";
import PageTitle from "components/common/PageTitle";
import CategoryTable from "../../components/category/CategoryTable";
import { connect } from "react-redux";

const Category = props => {
    console.log("this is testing", props);
    
    return (
        <StyleWrapper {...props}>
            <PageTitle title="categories" className="plr-15" iconClass="fas fa-list" />
            <div className="user-tbl">
                <CategoryTable {...props} />
            </div>
        </StyleWrapper>
    );
};

const mapStateToProps = state => {
    return {
        ...state.themeChanger
    };
};

export default connect(mapStateToProps, null)(Category);
