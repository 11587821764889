import React from "react";
import PageWrapper from "./pageManagement.style";
import PageTitle from "components/common/PageTitle";
import PageTable from "../../components/PageManagement/pageTable";
import { connect } from "react-redux";

const Page = props => {
  return (
    <PageWrapper {...props}>
      <PageTitle
        title="pages"
        className="plr-15"
        iconClass="fas fa-file-alt"
      />
      <div className="user-tbl">
        <PageTable {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Page);
