import React, { useState, Fragment }  from "react";
import { compose } from "redux";
import enhancer from "../Notification/validator";
import CustomToast from "../../customToast/CustomToast";

const TemplateForm = props => {

    const {
        handleChange,
        handleBlur,
        errors,
        touched,
        submitCount,
        onSubmit,
        values,
        action
    } = props;

    const [toast, showToast] = useState(false);

    const Error = props => {
        const field1 = props.field;
        if ((errors[field1] && touched[field1]) || submitCount > 0) {
            return (
                <span className={props.class ? props.class : "error-msg"}>
                    {errors[field1]}
                </span>
            );
        } else {
            return <span></span>;
        }
    };


    const handleSubmit = () => {
        props.handleSubmit();
        if (action === "edit") {
            const err = Object.getOwnPropertyNames(props.errors);
            if (props.isValid || !err.length) {
                let { values } = props;
                    const submitData = {
                        ...values,
                    };
                    onSubmit(submitData, action);
            }
        }
    };

    return (
        <Fragment>
            {toast && (
                <CustomToast
                    heading={"Alert!"}
                    width={400}
                    show="bottomRight"
                    transition
                    position="bottom-right"
                    className="c-danger"
                    message="You haven't changed Anything"
                    onCloseCLick={() => showToast(false)}
                />
            )}

            <form>
                {/* <div className="form-group">
                    <label className="fs-16 medium-text">Type<span className="c-text-danger">*</span></label>
                    <input
                        type="text"
                        className="form-control react-form-input"
                        id="type"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.type}
                        placeholder="Type"
                    />
                    <Error field="type" />
                </div> */}

                <div className="form-group">
                    <label className="fs-16 medium-text">Subject<span className="c-text-danger">*</span></label>
                    <input
                        type="text"
                        className="form-control react-form-input"
                        id="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        placeholder="title"
                    />
                    <Error field="title" />
                </div>
                 
                <div className="form-group">
                    <label className="fs-16 medium-text">Content<span className="c-text-danger">*</span></label> 
                    <input
                        type="text"
                        className="form-control react-form-input"
                        id="content"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.content}
                        placeholder="content"
                    />
                    <Error field="content" />
                </div>  
                <div>
                    <button
                        type="button"
                        className="c-btn c-info form-button fs-16 demi-bold-text"
                        style={{ maxWidth: "125px" }}
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </Fragment>
    );
};

export default compose(enhancer)(TemplateForm);