import React from "react";
import styled from "styled-components";
import { NavLink } from 'react-router-dom';

const Card = props => {
  const Wrapper = styled.div`
    border: 2px solid ${props.bgColor};
    margin: 10px;
    margin-top: 20px;
    border-radius: 3px;
    background-color: ${props.bgColor};
    display: flex;
    flex-flow: column;
    box-sizing: border-box;
    text-align: center;
    height: 100px;
    text-decoration:none;
    justify-content: center;
    align-items: center;
    box-shadow:none;
    transition-property: box-shadow;
    transition-duration:0.5s;
    a:hover{
      color:whitesmoke !important;
      text-decoration:none !important;
    }

    .count {
      font-style: bold;
      color: whitesmoke;
      font-size: 24px;
      align-self: center;
    }
    .title {
      font-size: 20px;
      align-self: center;
      color: whitesmoke;
      display: flex;
      justify-content: center;
      align-items: baseline;
    }
    &:hover {
    box-shadow: 2px 5px 15px ${props.bgColor || 'rgb(114, 102, 186)'};
    cursor: pointer;
    color:whitesmoke;
    text-decoration:none !important;
  }
  .transaction{
    display: flex;
    justify-content: space-around;
  }
  `;
  return (
    <>
      {props.to ? <NavLink to={props.to}>
        <Wrapper>
          <div className="count">{props.count}</div>
          <div className="title"><i style={{ fontSize: "15px", marginRight: "10px" }} className={props.icon}></i>{props.title}</div>
        </Wrapper>
      </NavLink> :
        <Wrapper style={{ justifyContent: "space-around", alignItems: "stretch" }}>
          <div className="transaction">
            <div className="title"><i style={{ fontSize: "15px", marginRight: "10px" }} className={props.icon}></i>{props.title}</div>

            <div className="count" style={{ fontSize: "20px" }}>{props.count}</div>
          </div>
          <div className="transaction">
            <div className="title"><i style={{ fontSize: "15px", marginRight: "10px" }} className={props.icon}></i>{props.title1}</div>
            <div className="count" style={{ fontSize: "20px" }}>{props.count1}</div>
          </div>
        </Wrapper>
      }
    </>
  );
};

export default Card;
