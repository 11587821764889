import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router";
import ReactTable from "react-table-6";
import Pagination from "components/common/Pagination";
import UserWrapper from "../../../assets/styledComponents/user.style";
import Button from "components/button/Button";
import RoyTooltip from "components/common/RoyTooltip";
//import CustomToast from "../../../components/customToast/CustomToast";
import item from '../../../assets/images/image.jpg';
import { getPagination } from "../../../helper/constant";
import classes from './Menu.module.css';

import { connect } from "react-redux";
import { menuCategoryList, changeMenuStatus } from "../../../apis";

let debounceTimer;

const MenuTable = props => {
  const history = useHistory();
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pages, setPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [params, setParams] = useState({
    barId: props.match.params.id,
    orderBy: "id",
    orderSequence: "ASC",
    pageNo: 1,
    pageSize: 25,
    search: "",
    isExport: false
  });
  //const [toast, showToast] = useState(false);
  // const [toastMsg, setMsg] = useState("");
  const hasParam = props.match.params.hasOwnProperty("id");

  useEffect(() => {
    if (hasParam) {
      setParams({
        barId: props.match.params.id,
        orderBy: "id",
        orderSequence: "ASC",
        pageNo: 1,
        pageSize: 25,
        search: "",
        isExport: false
      });
    }
  }, []);
  useEffect(() => {
    if (searchText !== null) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
      debounceTimer = setTimeout(callSearchApi, 500);
    }
  }, [searchText]);

  useEffect(() => {
    menuCategoryList(params).then(res => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = getPagination(res.data.totalRecords, params.pageSize);
        setPages(pages);
      }
    });
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
    };
  }, []);

  const getUserList = () => {
    // setLoader(true);
    menuCategoryList(params).then(res => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = getPagination(res.data.totalRecords, params.pageSize);
        setPages(pages);
      }
    });
  };

  const onPageChangeHandler = index => {
    let page = index + 1;
    setActivePage(page);
    let data = {
      ...params,
      pageNo: page
    };
    setParams(data);
    setLoader(true);
    menuCategoryList(data).then(res => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = getPagination(res.data.totalRecords, params.pageSize);
        setPages(pages);
      }
    });
  };

  const callSearchApi = () => {
    const data = {
      ...params,
      search: searchText
    };
    setParams(data);
    setLoader(true);
    menuCategoryList(data).then(res => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = getPagination(res.data.totalRecords, params.pageSize);
        setPages(pages);
      }
    });
  };

  const onSortChange = sortProperties => {
    const orderByDirection = sortProperties[0].desc ? 1 : 0;
    if (!orderByDirection) {
      let data = {
        ...params,
        orderBy: sortProperties[0].id,
        orderSequence: "DESC"
      };
      setParams(data);
      setLoader(true);
      menuCategoryList(data).then(res => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
          const pages = getPagination(res.data.totalRecords, params.pageSize);
          setPages(pages);
        }
      });
    } else {
      let data = {
        ...params,
        orderSequence: "ASC"
      };
      setParams(data);
      setLoader(true);
      menuCategoryList(data).then(res => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
          const pages = getPagination(res.data.totalRecords, params.pageSize);
          setPages(pages);
        }
      });
    }
  };

  const activeInactiveStatusHandler = (id, barId) => {
    setLoader(true);
    changeMenuStatus(id, barId).then(res => {
      getUserList();

    });
  };


  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: "Name",
  //       accessor: "name",
  //       className: "text-center",
  //       filterable: false,
  //       headerClassName: "react-table-header-class"
  //     },
  //     {
  //       Header: "Description",
  //       accessor: "description",
  //       className: "text-center",
  //       filterable: false,
  //       headerClassName: "react-table-header-class"
  //     },
  //     {
  //       Header: "Price",
  //       accessor: "price",
  //       className: "text-center",
  //       filterable: false,
  //       headerClassName: "react-table-header-class"
  //     },
  //     {
  //       Header: "Status",
  //       accessor: "status",
  //       className: "text-center",
  //       headerClassName: "react-table-header-class",
  //       sortable: false,
  //       filterable: false,
  //       width: 180,
  //       Cell: props => (
  //         <div className="react-action-class">
  //           {props.original.status === "Active" ? (
  //             <Fragment>
  //               <div id={`active-${props.original.id}`}>
  //                 <Button
  //                   style={{ minWidth: "125px" }}
  //                   className="c-btn c-info mr-10"
  //                   onClick={() =>
  //                     activeInactiveStatusHandler(props.original.id, props.original.barID)
  //                   }>
  //                   <div className="fs-14 medium-text">
  //                     <i className="fas fa-toggle-off mr-6" /> Active
  //                   </div>
  //                 </Button>
  //               </div>
  //               <RoyTooltip
  //                 id={`active-${props.original.id}`}
  //                 title={"Click to Deactivate"}
  //                 placement="left"></RoyTooltip>
  //             </Fragment>
  //           ) : (
  //             <RoyTooltip
  //               id={`deactive-${props.original.id}`}
  //               title={"Click to Active"}
  //               placement="left">
  //               <div id={`deactive-${props.original.id}`}>
  //                 <Button
  //                   style={{ minWidth: "125px" }}
  //                   className="c-btn c-warning mr-10"
  //                   onClick={() =>
  //                     activeInactiveStatusHandler(props.original.id)
  //                   }>
  //                   <div className="fs-14 medium-text">
  //                     <i className="fas fa-toggle-on mr-6" /> InActive
  //                   </div>
  //                 </Button>
  //               </div>
  //             </RoyTooltip>
  //           )}
  //         </div>
  //       ) // Custom cell components!
  //     },
  //     ///bars/category/menu
  //     {
  //       Header: "Created Date",
  //       accessor: "createdAt",
  //       className: "text-center",
  //       filterable: false,
  //       sortable: true,
  //       width: 150,
  //       headerClassName: "react-table-header-class -sort",
  //       Cell: props => {
  //         return `${new Date(props.original.createdAt).getDate()}/${new Date(props.original.createdAt).getMonth() + 1}/${new Date(props.original.createdAt).getFullYear()}`;
  //       },
  //     }
  //   ],
  //   [params]
  // );

  const columns = React.useMemo(
    () => [
      {
        Header: "Category",
        accessor: "name",
        className: "text-center",
        filterable: false,
        headerClassName: "react-table-header-class",
        Cell: props => <p>{props.original.sub_category.name}</p>
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   className: "text-center",
      //   headerClassName: "react-table-header-class",
      //   sortable: false,
      //   filterable: false,
      //   width: 180,
      //   Cell: props => (
      //     <div className="react-action-class">
      //       {props.original.sub_category.status === "Active" ? (
      //         <Fragment>
      //           <div id={`active-${props.original.id}`}>
      //             <Button
      //               style={{ minWidth: "125px" }}
      //               className="c-btn c-info mr-10"
      //               onClick={() =>
      //                 activeInactiveStatusHandler(props.original.id, props.original.barID)
      //               }>
      //               <div className="fs-14 medium-text">
      //                 <i className="fas fa-toggle-off mr-6" /> Active
      //               </div>
      //             </Button>
      //           </div>
      //           <RoyTooltip
      //             id={`active-${props.original.id}`}
      //             title={"Click to Deactivate"}
      //             placement="left"></RoyTooltip>
      //         </Fragment>
      //       ) : (
      //         <RoyTooltip
      //           id={`deactive-${props.original.id}`}
      //           title={"Click to Active"}
      //           placement="left">
      //           <div id={`deactive-${props.original.id}`}>
      //             <Button
      //               style={{ minWidth: "125px" }}
      //               className="c-btn c-warning mr-10"
      //               onClick={() =>
      //                 activeInactiveStatusHandler(props.original.id)
      //               }>
      //               <div className="fs-14 medium-text">
      //                 <i className="fas fa-toggle-on mr-6" /> InActive
      //               </div>
      //             </Button>
      //           </div>
      //         </RoyTooltip>
      //       )}
      //     </div>
      //   ) // Custom cell components!
      // },
      ///bars/category/menu
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        filterable: false,
        // width: 150,
        Cell: (props) => (
          <div className="react-action-class">
            <Button
              className="c-btn c-success mr-10"
              onClick={
                () =>
                  history.push({
                    pathname: `/bars/category/${props.original.sub_category.name}`,
                    state: { catId: props.original.subCategoryID, id: props.original.barID },
                  })
              }
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-utensils mr-6" /> Menu
              </div>
            </Button>
          </div>
        ),
      },
      {
        Header: "Created Date",
        accessor: "createdAt",
        className: "text-center",
        filterable: false,
        sortable: true,
        // width: 150,
        headerClassName: "react-table-header-class -sort",
        Cell: props => {
          return `${new Date(props.original.sub_category.createdAt).getDate()}/${new Date(props.original.sub_category.createdAt).getMonth() + 1}/${new Date(props.original.sub_category.createdAt).getFullYear()}`;
        },
      },
    ],
    [params]
  );

  return (
    <UserWrapper {...props}>
      {/* {toast && (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className="c-danger"
          message={toastMsg}
          onCloseCLick={() => showToast(false)}
        />
      )} */}
      <div className="pos-relative">

        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/bars")}
        >
          <i className="fas fa-step-backward"></i> Backward
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span>{" "}
              Category
            </div>
            <div className="mr-10">
              <input
                value={searchText ? searchText : ""}
                onChange={e => setSearchText(e.target.value)}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
          </div>

          <div className="roe-card-body">
            <div className="roe-card-body">
              <ReactTable
                style={{
                  border: "none",
                  boxShadow: "none"
                }}
                data={tblData}
                columns={columns}
                defaultPageSize={2}
                minRows={0}
                pages={pages}
                filterable
                manual
                className="-striped -highlight custom-react-table-theme-class"
                page={activePage - 1}
                PaginationComponent={Pagination}
                loading={loader}
                onPageChange={pageIndex => onPageChangeHandler(pageIndex)}
                onSortedChange={sortProperties => onSortChange(sortProperties)}
              />
            </div>
          </div>
        </div>
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(
  mapStateToProps,
  null
)(MenuTable);
