import React, { useState, Fragment, useEffect } from "react";
import enhancer from "./validator";
import CustomToast from "../customToast/CustomToast";
import ProfileImg from "../ProfileModal/profileImage/ProfileImage";

import {parentList} from "../../apis/index";

const CategoryForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action
  } = props;

  const [categoryData, setCategoryData] = useState(null);

  useEffect(() => {
      parentList().then(res => {
            if (res && res.status) {
                setCategoryData(res.data.list);
            }
        });
  }, []);


  const [toast, showToast] = useState(false);
  const [avatar, setAvatar] = useState();

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const getImgObj = imgObject => {
    console.log("IMAGEOBJECT", imgObject);
    console.log(window.URL.createObjectURL(imgObject));

    var reader = new FileReader();
    reader.readAsDataURL(imgObject);
    reader.onload = function () {
      setAvatar(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleSubmit = () => {
    props.handleSubmit();
    if (action === "edit") {
      const err = Object.getOwnPropertyNames(props.errors);
      if (props.isValid || !err.length) {
        let { values } = props;
        const submitData = {
          ...values,
          avatar: avatar ? avatar : values.avatar
        };
        onSubmit(submitData, action);
      }
    }
    if (action === "add") {
      if (props.isValid) {

        let { values } = props;
        const subObj = {
          ...values,
          avatar: avatar ? avatar : values.avatar
        };
        onSubmit(subObj, action);
      }
    }
  };
  return (
    <Fragment>
      {toast && (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className="c-danger"
          message="You haven't changed Anything"
          onCloseCLick={() => showToast(false)}
        />
      )}
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">Name <span className="c-text-danger">*</span></label>
          <input
            type="text"
            className="form-control react-form-input"
            id="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            placeholder="Name"
          />
          <Error field="name" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Category Name <span className="c-text-danger">*</span></label>
          <select
            className="form-control react-form-input"
            id="categoryID"
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={values.categoryID}
            placeholder="Category"
          >
            <option value="0">Select Category</option>
            {categoryData &&

              categoryData.map((element, i) => {     
                let checked = '';
                if(element.id == values.categoryID) {
                  checked = 'selected';
                }                 
                // // Return the element. Also pass key     
                return (<option value={element.id} selected={checked}>{element.name}</option>) 
              })
            }
          </select>
          <Error field="categoryID" />
        </div>
        <div>
          <button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default enhancer(CategoryForm);
