export const AppName = "MyTab Pty Ltd";
export const drawerWidth = "260px";
export const miniDrawerWidth = "80px";
export const themeSettingDrawerWidth = "300px";
export const chatDrawerWidth = "260px";
export const chatMiniDrawerWidth = "0px";

export const getPagination = (totalCount, pageSize) => {
  if (totalCount >= pageSize) {
    let ceil = Math.ceil(totalCount / pageSize);
    return ceil;
  } else {
    return 1;
  }
};
export const ckConfig = {
  toolbar: [
    {
      name: "basicstyles",
      items: ["Bold", "Underline", "Italic", "RemoveFormat"]
    },
    { name: "links", items: ["Link", "Unlink"] },
    { name: "clipboard", items: ["Undo", "Redo"] },
    {
      name: "paragraph",
      groups: ["list", "blocks", "align", "bidi", "justify"],
      items: [
        "NumberedList",
        "BulletedList",
        "-",
        "Blockquote",
        "CreateDiv",
        "-",
        "JustifyLeft",
        "JustifyCenter",
        "JustifyRight",
        "JustifyBlock"
      ]
    },
    "/",
    { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
    { name: "colors", items: ["TextColor", "BGColor"] }
  ],
  extraPlugins: "justify,showblocks,font",
  // removePlugins : 'elementspath,save,image,flash,iframe,smiley,tabletools,find,pagebreak,templates,about,maximize,showblocks,newpage,language',
  removeButtons: "Subscript,Superscript"
};
export const userDemo = require("assets/images/user.png")
export const userPlaceHolder = require("assets/images/user.png");
export const brownShoe = require("assets/images/brown-shoe.jpeg");
export const speaker = require("assets/images/speaker.jpeg");
export const loginBack = require("assets/images/loginback.jpg");
export const iconDemo = require("assets/images/roelogo.png");
export const ForgotIcon = require("assets/images/forgotpassword.png");
export const locakscreenBack = require("assets/images/lockscreen.jpg");
export const ProfileLockScreen = require("assets/images/profile.jpg");
export const errorback = require("assets/images/errorback.jpg");
export const feed1 = require("assets/images/feed1.jpg");
export const feed2 = require("assets/images/feed2.jpg");
export const friend1 = require("assets/images/friend1.jpg");
export const friend2 = require("assets/images/friend2.jpg");
export const friend3 = require("assets/images/friend3.jpg");
export const friend4 = require("assets/images/friend4.jpg");
export const friend5 = require("assets/images/friend5.jpg");
export const friend6 = require("assets/images/friend6.jpg");
export const media1 = require("assets/images/media1.jpeg");
export const media2 = require("assets/images/media2.jpeg");
export const media3 = require("assets/images/media3.jpeg");
export const media4 = require("assets/images/media4.jpeg");
export const media5 = require("assets/images/media5.jpeg");
export const media6 = require("assets/images/media6.jpeg");
export const media7 = require("assets/images/media7.jpeg");
export const media8 = require("assets/images/media8.jpeg");
export const media9 = require("assets/images/media9.jpeg");
export const sidebar1 = require("assets/images/sidebar1.jpg");
export const sidebar2 = require("assets/images/sidebar2.jpg");
export const sidebar3 = require("assets/images/sidebar3.jpg");
export const sidebar4 = require("assets/images/sidebar4.jpg");
export const sidebar5 = require("assets/images/sidebar5.jpg");
export const sidebar6 = require("assets/images/sidebar6.jpg");
export const sidebar7 = require("assets/images/sidebar7.jpg");
export const sidebar8 = require("assets/images/sidebar8.jpg");
export const Img6464 = require("assets/images/6464Img.svg");
export const pdfIcon = require("assets/images/pdf.svg");
