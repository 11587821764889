import React, { useState, useEffect } from "react";
import ReactTable from "react-table-6";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";

import "react-table-6/react-table.css";
import { withRouter } from "react-router";
import {
    viewUsersPromoCode,
} from "../../apis/index";
import { getPagination } from "../../helper/constant";

let debounceTimer;
// const REDIRECT_URL = process.env.REACT_APP_LOCAL_CMS_URL+'bars';
const REDIRECT_URL = process.env.REACT_APP_STAGING_CMS_URL + 'bars';
const BarPromoTable = props => {
    const [tblData, settblData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [pages, setPages] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [searchText, setSearchText] = useState(null);
    const [params, setParams] = useState({
        isExport: false,
        orderBy: "id",
        orderSequence: "DESC",
        pageNo: 1,
        pageSize: 8,
        search: "",
    });
    const data = {
        ...props.location.state
    };
    const [userDetails, setUserDetails] = useState({});
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const {
        sidebarTheme: { activeColor, textColor }
    } = props;


    useEffect(() => {
        let apiData = {
            ...params,
            barId: props.location.state.id,
            userId: 0
        };
        viewUsersPromoCode(apiData).then(res => {
            if (res && res.status) {
                settblData(res.data.list);
                setLoader(false);
                const pages = getPagination(res.data.totalCount, params.pageSize);
                setPages(pages);
            }
        });
        return () => {
            if (debounceTimer) {
                clearTimeout(debounceTimer);
                debounceTimer = null;
            }
        };
    }, []);

    const convertArrayOfObjectsToCSV = (args) => {
        let result, ctr, keys, columnDelimiter, lineDelimiter, data;
        data = args.data || null;
        if (data == null || !data.length) {
            return null;
        }

        columnDelimiter = ',';
        lineDelimiter = '\n';

        keys = Object.keys(data[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach((item) => {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });
        return result;
    };

    const exportToCSV = () => {

        viewUsersPromoCode({
            isExport: true,
            orderBy: "id",
            orderSequence: "DESC",
            pageNo: 1,
            pageSize: 8,
            search: "",
            barId: props.location.state.id,
            userId: 0
        }).then(res => {
            if (res && res.status) {
                var data, filename, link;

                var csv = convertArrayOfObjectsToCSV({
                    data: res.data.list
                });

                if (csv == null) return;

                filename = 'userspromolist.csv';

                if (!csv.match(/^data:text\/csv/i)) {
                    csv = 'data:text/csv;charset=utf-8,' + csv;
                }
                data = encodeURI(csv);

                link = document.createElement('a');
                link.setAttribute('href', data);
                link.setAttribute('download', filename);
                link.click();

            }
        }).catch(err => {
            console.log(err);
        });

    };

    const columns = React.useMemo(
        () => [
            {
                Header: "OrderId",
                accessor: "order_id",
                className: "text-center",
                filterable: false,
                headerClassName: "react-table-header-class"
            },
            {
                Header: "OrderNo",
                accessor: "orderNo",
                className: "text-center",
                filterable: false,
                headerClassName: "react-table-header-class"
            },
            {
                Header: "PickupCode",
                accessor: "pickupCode",
                className: "text-center",
                filterable: false,
                headerClassName: "react-table-header-class"
            },
            {
                Header: "PromoCodeAmount",
                accessor: "promocode_amount",
                className: "text-center",
                filterable: false,
                headerClassName: "react-table-header-class"
            },
            {
                Header: "UserName",
                accessor: "bar",
                className: "text-center",
                filterable: false,
                headerClassName: "react-table-header-class",
                Cell: props => {
                    return props['original']['user.fullName'];
                }
            },
            {
                Header: "OrderDate",
                accessor: "orderDate",
                className: "text-center",
                filterable: false,
                headerClassName: "react-table-header-class"
            },
            {
                Header: "OrderStatus",
                accessor: "orderStatus",
                className: "text-center",
                filterable: false,
                headerClassName: "react-table-header-class"
            },
            {
                Header: "CouponCode",
                accessor: "coupon",
                className: "text-center",
                filterable: false,
                headerClassName: "react-table-header-class",
                Cell: props => {
                    return props['original']['coupon.code'];
                }
            },
            {
                Header: "CreatedAt",
                accessor: "createdAt",
                className: "text-center",
                filterable: false,
                headerClassName: "react-table-header-class",
                Cell: props => {
                    return `${new Date(props.original.createdAt).getDate()}/${new Date(props.original.createdAt).getMonth() + 1}/${new Date(props.original.createdAt).getFullYear()}`;
                }
            },

        ],
        [params]
    );

    return (
        <React.Fragment>
            <div className="plr-15">
                <div className="roe-card-style mtb-15">
                    <div className="roe-card-header module-header">
                        <div className="flex-1 fs-16 demi-bold-text">
                            <span className="hash"># </span> Bar PromoCode List - {props.location.state.restaurantName}
                            {/* <div
                                className="back-icon fs-15 demi-bold-text cursor-pointer"
                                onClick={() => 
                                    // props.history.push("/bars")
                                    window.location.href = `${REDIRECT_URL}`
                                }
                                >
                                <i className="fas fa-step-backward"></i> Backward
                            </div> */}
                        </div>
                        <div>
                            <Button
                                className="c-btn ma-5 c-warning"
                                onClick={() => exportToCSV()}
                                style={{
                                    backgroundColor: activeColor,
                                    color: textColor
                                }}>
                                <i className="fas fa-file-export mr-10"></i>Export
                            </Button>
                        </div>
                    </div>
                    <div className="roe-card-body">
                        <ReactTable
                            style={{
                                border: "none",
                                boxShadow: "none"
                            }}
                            data={tblData}
                            columns={columns}
                            defaultPageSize={2}
                            minRows={0}
                            pages={pages}
                            filterable
                            manual
                            className="-striped -highlight custom-react-table-theme-class"
                            page={activePage - 1}
                            PaginationComponent={Pagination}
                            loading={loader}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(BarPromoTable);