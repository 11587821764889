import styled from "styled-components";

export const Container = styled.div`
	.center-btn,
	.center-text {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
