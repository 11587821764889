import React, { useState, useEffect } from "react";
import ReactTable from "react-table-6";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";

import "react-table-6/react-table.css";
import { withRouter } from "react-router";
import {
    templateList,
} from "../../../apis/index";
import { getPagination } from "../../../helper/constant";

let debounceTimer;

const TemplateTable = props => {
    const [tblData, settblData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [pages, setPages] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [searchText, setSearchText] = useState(null);
    const [params, setParams] = useState({
        isExport: false,
        orderBy: "id",
        orderSequence: "ASC",
        pageNo: 1,
        pageSize: 8,
        search: "",
        type: "notification"
    });
    const [userDetails, setUserDetails] = useState({});
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const {
        sidebarTheme: { activeColor, textColor }
    } = props;

    useEffect(() => {
        if (searchText !== null) {
            if (debounceTimer) {
                clearTimeout(debounceTimer);
                debounceTimer = null;
            }
            debounceTimer = setTimeout(callSearchApi, 500);
        }
    }, [searchText]);


    useEffect(() => {
        templateList(params).then(res => {
            if (res && res.status) {
                settblData(res.data.list);
                setLoader(false);
                const pages = getPagination(res.data.totalCount, params.pageSize);
                setPages(pages);
            }
        });
        return () => {
            if (debounceTimer) {
                clearTimeout(debounceTimer);
                debounceTimer = null;
            }
        };
    }, []);

    // const convertArrayOfObjectsToCSV = (args) => {
    //     let result, ctr, keys, columnDelimiter, lineDelimiter, data;
    //     data = args.data || null;
    //     if (data == null || !data.length) {
    //         return null;
    //     }

    //     columnDelimiter = ',';
    //     lineDelimiter = '\n';

    //     keys = Object.keys(data[0]);

    //     result = '';
    //     result += keys.join(columnDelimiter);
    //     result += lineDelimiter;

    //     data.forEach((item) => {
    //         ctr = 0;
    //         keys.forEach(function (key) {
    //             if (ctr > 0) result += columnDelimiter;

    //             result += item[key];
    //             ctr++;
    //         });
    //         result += lineDelimiter;
    //     });
    //     return result;
    // }

    // const exportToCSV = () => {

    //     templateList({
    //         isExport: true,
    //         orderBy: "id",
    //         orderSequence: "ASC",
    //         pageNo: 1,
    //         pageSize: 8,
    //         search: "",
    //         type:"email"
    //     }).then(res => {
    //         if (res && res.status) {
    //             var data, filename, link;

    //             var csv = convertArrayOfObjectsToCSV({
    //                 data: res.data.list
    //             });

    //             if (csv == null) return;

    //             filename = 'email_template.csv';

    //             if (!csv.match(/^data:text\/csv/i)) {
    //                 csv = 'data:text/csv;charset=utf-8,' + csv;
    //             }
    //             data = encodeURI(csv);

    //             link = document.createElement('a');
    //             link.setAttribute('href', data);
    //             link.setAttribute('download', filename);
    //             link.click();

    //         }
    //     }).catch(err => {
    //         console.log(err)
    //     })

    // }

    // const getUserList = () => {
    //     templateList(params).then(res => {
    //         if (res && res.status) {
    //             settblData(res.data.list);
    //             setLoader(false);
    //             const pages = getPagination(res.data.totalCount, params.pageSize);
    //             setPages(pages);
    //         }
    //     });
    // };

    const onPageChangeHandler = index => {
        let page = index + 1;
        setActivePage(page);
        let data = {
            ...params,
            pageNo: page
        };
        setParams(data);
        templateList(data).then(res => {
            if (res && res.status) {
                settblData(res.data.list);
                setLoader(false);
                const pages = getPagination(res.data.totalCount, params.pageSize);
                setPages(pages);
            }
        });
    };

    const callSearchApi = () => {
        const data = {
            ...params,
            search: searchText
        };
        setParams(data);
        templateList(data).then(res => {
            if (res && res.status) {
                settblData(res.data.list);
                setLoader(false);
                const pages = getPagination(res.data.totalCount, params.pageSize);
                setPages(pages);
            }
        });
    };

    const onSortChange = sortProperties => {
        const orderByDirection = sortProperties[0].desc ? 1 : 0;
        if (!orderByDirection) {
            let data = {
                ...params,
                orderBy: sortProperties[0].id,
                orderSequence: "DESC"
            };
            setParams(data);
            templateList(data).then(res => {
                if (res && res.status) {
                    settblData(res.data.list);
                    setLoader(false);
                    const pages = getPagination(res.data.totalCount, params.pageSize);
                    setPages(pages);
                }
            });
        } else {
            let data = {
                ...params,
                orderSequence: "ASC"
            };
            setParams(data);
            templateList(data).then(res => {
                if (res && res.status) {
                    settblData(res.data.list);
                    setLoader(false);
                    const pages = getPagination(res.data.totalCount, params.pageSize);
                    setPages(pages);
                }
            });
        }
    };

    const formAction = (action, data = null) => {
        console.log("Edit 1", data);
        if (action === "edit") {
            props.history.push(`/template/notification/edit/${data.id}`, {
                ...data
            });
        }
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Type",
                accessor: "type",
                className: "text-center",
                filterable: false,
                headerClassName: "react-table-header-class"
            },
            {
                Header: "Subject",
                accessor: "title",
                className: "text-center",
                filterable: false,
                headerClassName: "react-table-header-class"
            },
            {
                Header: "Action",
                accessor: "action",
                className: "text-center",
                headerClassName: "react-table-header-class",
                sortable: false,
                filterable: false,
                width: 300,
                Cell: props => (
                    <div className="react-action-class">
                        <Button
                            className="c-btn c-info mr-10"
                            onClick={() => formAction("edit", props.original)}>
                            <div className="fs-14 medium-text">
                                <i className="fas fa-edit mr-6" /> Edit
                            </div>
                        </Button>
                    </div>
                )
            },
        ],
        [params]
    );

    return (
        <React.Fragment>
            {/* <ViewUserModal userDetails={userDetails} toggle={toggle} modal={modal} /> */}
            <div className="plr-15">
                <div className="roe-card-style mtb-15">
                    <div className="roe-card-header module-header">
                        <div className="flex-1 fs-16 demi-bold-text">
                            <span className="hash"># </span> Notification Template
                        </div>
                        {/* <div className="mr-10">
                            <input
                                value={searchText ? searchText : ""}
                                onChange={e => setSearchText(e.target.value)}
                                type="text"
                                placeholder="Search..."
                                className="fs-14 medium-text plr-10 form-control react-form-input"
                            />
                        </div> */}
                        {/* <div>
                            <Button
                                className="c-btn ma-5 c-warning"
                                onClick={() => exportToCSV()}
                                style={{
                                    backgroundColor: activeColor,
                                    color: textColor
                                }}>
                                <i className="fas fa-file-export mr-10"></i>Export
              </Button>
                        </div> */}
                    </div>
                    <div className="roe-card-body">
                        <ReactTable
                            style={{
                                border: "none",
                                boxShadow: "none"
                            }}
                            data={tblData}
                            columns={columns}
                            defaultPageSize={2}
                            minRows={0}
                            pages={pages}
                            filterable
                            manual
                            className="-striped -highlight custom-react-table-theme-class"
                            page={activePage - 1}
                            PaginationComponent={Pagination}
                            loading={loader}
                            onPageChange={pageIndex => onPageChangeHandler(pageIndex)}
                            onSortedChange={sortProperties => onSortChange(sortProperties)}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(TemplateTable);