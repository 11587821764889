import React, { useEffect, useState } from "react";
import UserWrapper from "./pageManagement.style";
import PageTitle from "components/common/PageTitle";
import PageForm from "../../components/PageManagement/PageForm";
import { connect } from "react-redux";
import { addPage, editPage } from "../../apis/index";
import CustomToast from "../../components/customToast/CustomToast";

const PageEdit = props => {
  const [editedData, setEditedData] = useState(props.location.state);
  const [toast, showToast] = useState(false);
  const [toastMsg, setMsg] = useState("");
  const hasParam = props.match.params.hasOwnProperty("id");

  useEffect(() => {
    if (hasParam) {
      const data = {
        ...props.location.state
      };
      setEditedData(data);
    }
  }, []);

  const submitFormHandler = (data, action) => {
    if (action === "add") {
      const apiData = {
        content: data.content,
        title: data.title,
      };
      addPage(apiData).then(res => {
        if (res && res.status) {
          props.history.push("/pages");
        } else {
          setMsg(res.message);
          showToast(true);
        }
      });
    } else if (action === "edit") {
      const apiData = {
        id: props.location.state.id,
        content: data.content,
        title: data.title
      };
      editPage(apiData).then(res => {
        if (res && res.status) {
          props.history.push("/pages");
        } else {
          setMsg(res.message);
          showToast(true);
        }
      });
    }
  };

  return (
    <UserWrapper {...props}>
      {toast && (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className="c-danger"
          message={toastMsg}
          onCloseCLick={() => showToast(false)}
        />
      )}
      <div className="pos-relative">
        <PageTitle title="Pages" className="plr-15" iconClass="fas fa-file-alt" />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/pages")}>
          <i className="fas fa-step-backward"></i> Backward
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"} Page
            </div>
          </div>
          <div className="roe-card-body">
            <PageForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              action={hasParam ? "edit" : "add"}
            />
          </div>
        </div>
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(PageEdit);
