import React, { useState, useEffect } from "react";
import NumberFormat from 'react-number-format';
import DashboardWrapper from "./dashboard.style";
import { Container, Row, Col } from "reactstrap";
import Card from "./Card/Card";
import { viewStatistic } from "../../apis/index";

const DashboardCards = () => {
  const [counts, setCounts] = useState({
    users: 0,
    bars: 0,
    sub_categories: 0,
    promo_code: 0,
    orders: 0,
    total: 0,
    mytabTotal: 0,
  });

  useEffect(() => {
    viewStatistic().then(res => {
      if (res && res.status) {
        const data = {
          ...counts,
          users: res.data.list.users,
          bars: res.data.list.bars,
          sub_categories: res.data.list.sub_categories,
          promo_code: res.data.list.promo_code,
          orders: res.data.list.orders,
          total: res.data.list.totalRevenue,
          mytabTotal: res.data.list.myTabRevenue
        };
        setCounts(data);
      } else {
        setCounts(counts);
      }
    });
  }, []);

  return (
    <DashboardWrapper>
      <Container fluid>
        <Row>
          <Col xs={12} lg={4} md={6} sm={6}>
            <Card
              title="Users"
              count={counts.users}
              icon="fas fa-user-alt"
              bgColor="#3ac47d"
              to="/users"></Card>
          </Col>
          <Col xs={12} lg={4} md={6} sm={6}>
            <Card
              title="Venues"
              count={counts.bars}
              icon="fas fa-glass-martini"
              bgColor="#fa7252"
              to="/bars"></Card>
          </Col>
          <Col xs={12} lg={4} md={6} sm={6}>
            <Card
              title="Categories"
              count={counts.sub_categories}
              icon="fas fa-list"
              bgColor="#3ac47d"
              to="/categories"></Card>
          </Col>
          <Col xs={12} lg={4} md={6} sm={6}>
            <Card
              title="Promo Code"
              count={counts.promo_code}
              icon="fas fa-tags"
              bgColor="#fa7252"
              to="/promo-code"></Card>
          </Col>
          <Col xs={12} lg={4} md={6} sm={6}>
            <Card
              title="Orders"
              count={counts.orders}
              icon="fas fa-utensils"
              bgColor="#3ac47d"
              to="/venue-orders"></Card>
          </Col>
          <Col xs={12} lg={4} md={6} sm={6}>
            <Card
              title="Total Revenue"
              count={<NumberFormat style={{ letterSpacing: "1px" }} value={counts.total} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} />}
              title1="MyTab Revenue"
              count1={<NumberFormat style={{ letterSpacing: "1px" }} value={counts.mytabTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} />}
              icon="fas fa-money-check-alt"
              bgColor="#fa7252"
            // to="/transactions"
            ></Card>
          </Col>
        </Row>
      </Container>
    </DashboardWrapper>
  );
};

export default DashboardCards;
