import React from "react";
import StyleWrapper from "../../assets/styledComponents/user.style";
import PageTitle from "components/common/PageTitle";
import PromoCodeTable from "../../components/promoCode/PromoCodeTable";
import { connect } from "react-redux";

const PromoCode = props => {
    console.log("this is testing", props);
    
    return (
        <StyleWrapper {...props}>
            <PageTitle title="Promo Code" className="plr-15" iconClass="fas fa-tags" />
            <div className="user-tbl">
                <PromoCodeTable {...props} />
            </div>
        </StyleWrapper>
    );
};

const mapStateToProps = state => {
    return {
        ...state.themeChanger
    };
};

export default connect(mapStateToProps, null)(PromoCode);
