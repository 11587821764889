import React, { useState, Fragment } from "react";
import enhancer from "./validator";
import CustomToast from "../customToast/CustomToast";
import ProfileImg from "../ProfileModal/profileImage/ProfileImage";

const AWS_BAR_URL = process.env.REACT_APP_S3URL + "bar/original/";

const CompanyForm = (props) => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
  } = props;

  console.log("form values ==>", values);

  const [toast, showToast] = useState(false);
  const [avatar, setAvatar] = useState();

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const getImgObj = (imgObject) => {
    console.log("IMAGEOBJECT", imgObject);
    console.log(window.URL.createObjectURL(imgObject));

    var reader = new FileReader();
    reader.readAsDataURL(imgObject);
    reader.onload = function() {
      setAvatar(reader.result);
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  };

  const handleSubmit = () => {
    props.handleSubmit();
    if (action === "edit") {
      const err = Object.getOwnPropertyNames(props.errors);
      if (props.isValid || !err.length) {
        let { values } = props;
        const submitData = {
          ...values,
          avatar: avatar ? avatar : values.avatar,
        };
        onSubmit(submitData, action);
      }
    }
    if (action === "add") {
      if (props.isValid) {
        let { values } = props;
        const subObj = {
          ...values,
          avatar: avatar ? avatar : values.avatar,
        };
        onSubmit(subObj, action);
      }
    }
  };
  return (
    <Fragment>
      {toast && (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className="c-danger"
          message="You haven't changed Anything"
          onCloseCLick={() => showToast(false)}
        />
      )}
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Restaurant Name <span className="c-text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="restaurantName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.restaurantName}
            placeholder="Restaurant Name"
          />
          <Error field="restaurantName" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Manager Name <span className="c-text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="managerName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.managerName}
            placeholder="Manager Name"
          />
          <Error field="managerName" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Transaction Fee <span className="c-text-danger">*</span>
          </label>
          <input
            type="string"
            className="form-control react-form-input"
            id="transactionFee"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.transactionFee}
            placeholder="Fee"
          />
          <Error field="transactionFee" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Email <span className="c-text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            placeholder="Enter Email"
          />
          <Error field="email" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Address <span className="c-text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address}
            placeholder="Enter Address"
          />
          <Error field="address" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Longitude<span className="c-text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="longitude"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.longitude}
            placeholder="Enter Longitude"
          />
          <Error field="longitude" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Latitude<span className="c-text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="latitude"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.latitude}
            placeholder="Enter Latitude"
          />
          <Error field="latitude" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Mobile<span className="c-text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="mobile"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.mobile}
            placeholder="Phone No"
          />
          <Error field="mobile" />
        </div>

        <div className="form-group">
          <label className="fs-16 medium-text">Avatar</label>
          <ProfileImg
            className="text-left"
            getImgObj={getImgObj}
            url={values.avatar ? `${values.avatar}` : ""}
          />
        </div>
        <div>
          <button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default enhancer(CompanyForm);
