import axios from "axios";
import { logout } from "../redux/auth/actions";
import { store } from "../redux/store";

//LOCAL
// const BASEURL = process.env.REACT_APP_LOCAL_URL;
//STAGING
const BASEURL = process.env.REACT_APP_STAGING_URL;

const setAccessToken = () =>
  (axios.defaults.headers.common[
    "Authorization"
  ] = store.getState().auth.accessToken);

const handleError = (err) => {
  if (err.response && err.response.status === 401) {
    store.dispatch(logout());
  } else {
    console.log(err);
  }
};

//CMS-AUTH APIS

export const signIn = (data) => {
  return axios
    .post(`${BASEURL}/auth/login`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const setUpMfa = (data) => {
  return axios
    .post(`${BASEURL}/auth/setup-mfa`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const verifyMfa = (data) => {
  return axios
    .post(`${BASEURL}/auth/verify-mfa`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const signOut = () => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/auth/logout`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const forgotPassword = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/auth/forgotpassword`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const resetPassword = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/auth/resetpassword`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

// USER MANAGEMENT

export const viewAllUser = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/user/userlist`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const viewOneUser = (userId) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/user/viewuser/${userId}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const deleteUser = (userId) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/user/deleteuser/${userId}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const activateDeactivateUser = (userId) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/user/changestatus/${userId}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const editUser = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/user/edituser`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

// BAR MANAGEMENT

export const addBar = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/bar/addbar`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const editBar = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/bar/editbar`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const barList = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/bar/barlist`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const showBarDetails = (id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/bar/showbardetails/${id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const deleteBar = (id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/bar/deletebar/${id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const changeBarStatus = (id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/bar/changestatus/${id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const changeBarEditorStatus = (id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/bar/changeEditorStatus/${id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const changeBarDocketStatus = (id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/bar/changeDocketPrintingStatus/${id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const showDocuments = (id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/bar/showdocuments/${id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const verifyAccount = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/bar/verifyaccount`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const menuListings = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/bar/menulist`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const changeMenuStatus = (id, barID) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/bar/updatemenustatus/${id}/${barID}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

// Dashboard

export const viewStatistic = () => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/dashboard/viewstatistics`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

// Pages

export const addPage = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/pages/addpage`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const editPage = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/pages/editpage`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const pageList = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/pages/pagelist`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const showPageDetails = (id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/pages/showpage/${id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const changePageStatus = (id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/pages/changestatus/${id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const deleteStaticPage = (id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/pages/deletepage/${id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

// Category APIs
export const addCategory = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/subCategory/add`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const editCategory = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/subCategory/edit`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const categoryList = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/subCategory/list`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const menuCategoryList = (data) => {
  setAccessToken();
  return (
    axios
      .post(`${BASEURL}/bar/subcategory_list`, data)
      // .post(`${BASEURL}/bar/category`, data)
      .then((res) => res.data)
      .catch((err) => handleError(err))
  );
};

export const deleteCategory = (id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/subCategory/delete/${id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const changeCategoryStatus = (id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/subCategory/changestatus/${id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const parentList = () => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/subCategory/categoryList`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

// Promo Code APIs
export const addPromoCode = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/promo-code/add`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const editPromoCode = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/promo-code/edit`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const promoCodeList = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/promo-code/list`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const changePromoCodeStatus = (id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/promo-code/changestatus/${id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const showPromoCodeDetails = (id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/promo-code/details/${id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const checkCodeExist = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/promo-code/check-code-exist/`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

// Template APIs
// export const editTemplate = data => {
//     setAccessToken();
//     return axios
//         .post(`${BASEURL}/template/edit`, data)
//         .then(res => res.data)
//         .catch(err => handleError(err));
// };

export const editTemplate = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/template/edit`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const templateList = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/template/list`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const orderList = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/orders/orderList`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};
export const venueOrderList = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/bar/venueListOrder`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const showOrderDetails = (id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/orders/showorderdetails/${id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const viewUsersPromoCode = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/promo-code/usedpromolist`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const transactionList = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/transaction/list`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const showTransactionDetails = (id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/transaction/transactiondetails/${id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};
