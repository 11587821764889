import React from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import OtpInput from "react-otp-input";
import Backdrop from "../../backdrop/backdrop";
import enhancer from "./modalEnhancer";
import CustomToast from "../../customToast/CustomToast";
import { setUpMfa, verifyMfa } from "../../../apis";
import { ForgotIcon } from "../../../helper/constant";
import { login } from "../../../redux/auth/actions";
import { ModalWrapper } from "./modal.style";

class VerifyOtpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      msg: "",
      className: "",
    };
  }
  render() {
    const Error = (props) => {
      const { errors, touched, submitCount } = this.props;
      const field1 = props.field;
      if ((errors[field1] && touched[field1]) || submitCount > 0) {
        return (
          <span className={props.class ? props.class : "error-msg"}>
            {errors[field1]}
          </span>
        );
      } else {
        return <span />;
      }
    };
    const handleSetupMFA = () => {
      setUpMfa({ email: this.props.modalData.email }).then((res) => {
        if (res && res.status === 1) {
          this.props.showSuccess();
        } else {
          if (res) {
            this.setState({
              show: true,
              msg: res.message,
              className: "c-danger",
            });
          }
        }
      });
    };
    const submitOtp = (e) => {
      e.preventDefault();
      this.props.handleSubmit();

      if (this.props.isValid) {
        const data = {
          email: this.props.modalData.email,
          code: this.props.values.otp,
        };
        verifyMfa(data).then((res) => {
          if (res && res.status === 1) {
            this.props.showSuccess();
            this.props.login(res.data);
            this.props.history.push("/dashboard");
          } else {
            if (res) {
              this.setState({
                show: true,
                msg: res.message,
                className: "c-danger",
              });
            }
          }
        });
      }
    };
    let Alert = <span></span>;

    if (this.state.show) {
      Alert = (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className={this.state.className}
          message={this.state.msg}
          onCloseCLick={() => this.setState({ show: false })}
        />
      );
    }
    return (
      <ModalWrapper>
        {Alert}
        <Backdrop clickHandler={this.props.closeModal} show={this.props.show} />
        <div className="Modal form-container">
          <div className="login-icon">
            <img src={ForgotIcon} alt="icon" height="100px" />
          </div>
          <div className="login-title">OTP Verification</div>
          <p className="fs-14 text-center pt-16">
            Please enter your 6 digit verification code
          </p>
          <form className="pa-24" onSubmit={submitOtp}>
            <div className="form-group">
              <div className="otpInputWrapper">
                <OtpInput
                  value={this.props.values.otp}
                  onChange={(otp) => this.props.setFieldValue("otp", otp)}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                  containerStyle={{
                    justifyContent: "center",
                  }}
                  shouldAutoFocus={true}
                />
              </div>
              <div className="text-center">
                <Error field="otp" />
              </div>
            </div>
            <div className="form-group">
              <button type="submit" className="btn form-button">
                Verify
              </button>
            </div>
            <p className="text-center fs-14">
              Don't have Google Authenticator set up yet?{" "}
              <span className="link-label" onClick={() => handleSetupMFA()}>
                Set up now
              </span>
              .
            </p>
          </form>
        </div>
      </ModalWrapper>
    );
  }
}

// export default enhancer(VerifyOtpModal);

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(login(data)),
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(null, mapDispatchToProps)
)(VerifyOtpModal);
