import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string().required("This field is required"),
    categoryID: Yup.string().required("This field is required")
  }),

  // isInitialValid: true,
  mapPropsToValues: props => ({
    name: props.editedData ? props.editedData.name : "",
    categoryID: props.editedData ? props.editedData.categoryID : ""
  }),
  handleSubmit: values => { },
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
