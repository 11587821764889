import { withFormik } from "formik";
import * as Yup from "yup";

const EmailRegEx = /\S+@\S+\.\S+/;

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .required("Please Enter Any Email")
      .matches(EmailRegEx, "Please enter valid Email")
  }),
  mapPropsToValues: props => ({
    email: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
