import React from "react";
import StyleWrapper from "../../../assets/styledComponents/user.style";
import PageTitle from "components/common/PageTitle";
import MenuTable from "./MenuTable";
import { connect } from "react-redux";

const User = props => {
  return (
    <StyleWrapper {...props}>
      <PageTitle title="CATEGORIES" className="plr-15" iconClass="fas fa-list" />
      <div className="user-tbl">
        <MenuTable {...props} />
      </div>
    </StyleWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(User);
