import React, { useState, useEffect, Fragment } from "react";
import ReactTable from "react-table-6";
import Button from "components/button/Button";
import ViewUserModal from "./vewUserModal";
import RoyTooltip from "components/common/RoyTooltip";
import Pagination from "components/common/Pagination";
import { iconDemo } from "helper/constant";
import { Container } from "./Users.style";
import "react-table-6/react-table.css";
import swal from "sweetalert";
import { withRouter, useHistory } from "react-router";
import {
  viewAllUser,
  viewOneUser,
  activateDeactivateUser,
  deleteUser,
} from "../../apis/index";
import { getPagination } from "../../helper/constant";

let debounceTimer;
const AWS_USER_URL = process.env.REACT_APP_S3URL + "user/original/";

const UserTable = (props) => {
  const history = useHistory();
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pages, setPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [params, setParams] = useState({
    isExport: false,
    orderBy: "id",
    orderSequence: "DESC",
    pageNo: 1,
    pageSize: 25,
    search: "",
  });
  const [userDetails, setUserDetails] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const {
    sidebarTheme: { activeColor, textColor },
  } = props;

  useEffect(() => {
    if (searchText !== null) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
      debounceTimer = setTimeout(callSearchApi, 500);
    }
  }, [searchText]);

  useEffect(() => {
    viewAllUser(params).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = getPagination(res.data.totalRecords, params.pageSize);
        setPages(pages);
      }
    });
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
    };
  }, []);

  const convertArrayOfObjectsToCSV = (args) => {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data;
    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = ",";
    lineDelimiter = "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item) => {
      ctr = 0;
      keys.forEach(function(key) {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });
    return result;
  };

  const exportToCSV = () => {
    viewAllUser({
      isExport: true,
      orderBy: "id",
      orderSequence: "DESC",
      pageNo: 1,
      pageSize: 25,
      search: "",
    })
      .then((res) => {
        if (res && res.status) {
          var data, filename, link;

          var csv = convertArrayOfObjectsToCSV({
            data: res.data.list,
          });

          if (csv == null) return;

          filename = "users.csv";

          if (!csv.match(/^data:text\/csv/i)) {
            csv = "data:text/csv;charset=utf-8," + csv;
          }
          data = encodeURI(csv);

          link = document.createElement("a");
          link.setAttribute("href", data);
          link.setAttribute("download", filename);
          link.click();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserList = () => {
    viewAllUser(params).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = getPagination(res.data.totalRecords, params.pageSize);
        setPages(pages);
      }
    });
  };

  const onPageChangeHandler = (index) => {
    let page = index + 1;
    setActivePage(page);
    let data = {
      ...params,
      pageNo: page,
    };
    setParams(data);
    viewAllUser(data).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = getPagination(res.data.totalRecords, params.pageSize);
        setPages(pages);
      }
    });
  };

  const callSearchApi = () => {
    const data = {
      ...params,
      search: searchText,
    };
    setParams(data);
    viewAllUser(data).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = getPagination(res.data.totalRecords, params.pageSize);
        setPages(pages);
      }
    });
  };

  const activeInactiveStatusHandler = (id) => {
    activateDeactivateUser(id).then((res) => {
      getUserList();
    });
  };

  const showOneUser = (data) => {
    viewOneUser(data).then((res) => {
      if (res && res.status) {
        setUserDetails(res.data);
        setModal(true);
      } else {
      }
    });
  };

  const onSortChange = (sortProperties) => {
    const orderByDirection = sortProperties[0].desc ? 1 : 0;
    if (!orderByDirection) {
      let data = {
        ...params,
        orderBy: sortProperties[0].id,
        orderSequence: "DESC",
      };
      setParams(data);
      viewAllUser(data).then((res) => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
          const pages = getPagination(res.data.totalRecords, params.pageSize);
          setPages(pages);
        }
      });
    } else {
      let data = {
        ...params,
        orderSequence: "ASC",
      };
      setParams(data);
      viewAllUser(data).then((res) => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
          const pages = getPagination(res.data.totalRecords, params.pageSize);
          setPages(pages);
        }
      });
    }
  };

  const deletePage = (pageId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteUser(pageId).then((res) => {
          if (res && res.status) {
            swal(res.message, {
              icon: "success",
            });
            viewAllUser(params).then((res) => {
              if (res && res.status) {
                settblData(res.data.list);
                setLoader(false);
                const pages = getPagination(
                  res.data.totalRecords,
                  params.pageSize
                );
                setPages(pages);
              }
              if (res && !res.data.list.length) {
                onPageChangeHandler(params.pageNo - 1);
              }
            });
            getUserList();
          } else {
            if (res) {
              swal(res.message, {
                icon: "danger",
              });
            }
          }
        });
      }
    });
  };

  const formAction = (action, data = null) => {
    console.log("EditData", data);
    if (action === "add") {
      props.history.push("/bars/add");
    } else if (action === "edit") {
      props.history.push(`/users/edit/${data.id}`, {
        ...data,
      });
    } else if (action === "view-promo") {
      props.history.push(`/users/view-promo/${data.id}`, {
        ...data,
      });
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: "fullName",
        className: "text-center",
        maxWidth: 200,
        filterable: false,
        headerClassName: "react-table-header-class",
        Cell: (props) => (
          <div
            className="react-action-class"
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            <img
              className="mx-2"
              height="30px"
              src={
                props.original.avatar ? `${props.original.avatar}` : iconDemo
              }
              alt="profile"
            />
            <p style={{ whiteSpace: "pre-wrap", textAlign: "initial" }}>
              {props.original.fullName}
            </p>
          </div>
        ),
      },
      {
        Header: "Phone No",
        accessor: "mobile",
        className: "text-center",
        filterable: false,
        sortable: true,
        width: 200,
        headerClassName: "react-table-header-class -sort",
        Cell: (props) => {
          return (
            <div className="center-text">
              ({props.original.countryCode}) {props.original.mobile}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        className: "text-center",
        headerClassName: "react-table-header-class",
        filterable: false,
        width: 125,
        Cell: (props) => (
          <div className="react-action-class center-btn">
            {props.original.status === "Active" ? (
              <Fragment>
                <div id={`active-${props.original.id}`}>
                  <Button
                    style={{ minWidth: "100px" }}
                    className="c-btn c-info mr-10"
                    onClick={() =>
                      activeInactiveStatusHandler(props.original.id)
                    }
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-off mr-6" /> Active
                    </div>
                  </Button>
                </div>
                <RoyTooltip
                  id={`active-${props.original.id}`}
                  title={"Click to Deactivate"}
                  placement="left"
                ></RoyTooltip>
              </Fragment>
            ) : (
              <RoyTooltip
                id={`deactive-${props.original.id}`}
                title={"Click to Active"}
                placement="left"
              >
                <div id={`deactive-${props.original.id}`}>
                  <Button
                    style={{ minWidth: "125px" }}
                    className="c-btn c-warning mr-10"
                    onClick={() =>
                      activeInactiveStatusHandler(props.original.id)
                    }
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-on mr-6" /> InActive
                    </div>
                  </Button>
                </div>
              </RoyTooltip>
            )}
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        filterable: false,
        width: 650,
        Cell: (props) => (
          <div className="react-action-class">
            <Button
              className="c-btn c-success mr-10"
              onClick={() => showOneUser(props.original.id)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-eye mr-6" /> View
              </div>
            </Button>
            <Button
              className="c-btn mr-10"
              style={{ backgroundColor: "#e9c46a" }}
              onClick={() =>
                history.push({
                  pathname: "/users/orders",
                  state: { userId: props.original.id },
                })
              }
            >
              <div className="fs-14 medium-text">
                <i class="fas fa-utensils mr-6"></i> Orders
              </div>
            </Button>
            <Button
              className="c-btn mr-10"
              style={{ backgroundColor: "#2a9d8f", color: "#fff" }}
              onClick={() => formAction("view-promo", props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-eye mr-6" /> PromoCode
              </div>
            </Button>
            <Button
              className="c-btn mr-10"
              style={{ backgroundColor: "#0077b6", color: "#fff" }}
              onClick={() => formAction("edit", props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit mr-6" /> Edit
              </div>
            </Button>

            <Button
              className="c-btn c-danger"
              onClick={() => deletePage(props.original.id)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-trash mr-6" /> Delete
              </div>
            </Button>
          </div>
        ),
      },
      {
        Header: "Created Date",
        accessor: "createdAt",
        className: "text-center",
        filterable: false,
        sortable: true,
        headerClassName: "react-table-header-class -sort",
        Cell: (props) => {
          return (
            <div className="center-text">
              {new Date(props.original.createdAt).getDate()}/
              {new Date(props.original.createdAt).getMonth() + 1}/
              {new Date(props.original.createdAt).getFullYear()}
            </div>
          );
        },
      },
    ],
    [params]
  );

  return (
    <React.Fragment>
      <ViewUserModal userDetails={userDetails} toggle={toggle} modal={modal} />
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Users
            </div>
            <div className="mr-10">
              <input
                value={searchText ? searchText : ""}
                onChange={(e) => setSearchText(e.target.value)}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            <div>
              <Button
                className="c-btn ma-5 c-warning"
                onClick={() => exportToCSV()}
                style={{
                  backgroundColor: activeColor,
                  color: textColor,
                }}
              >
                <i className="fas fa-file-export mr-10"></i>Export
              </Button>
            </div>
          </div>
          <Container className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={2}
              minRows={0}
              pages={pages}
              filterable
              manual
              className="-striped -highlight custom-react-table-theme-class"
              page={activePage - 1}
              PaginationComponent={Pagination}
              loading={loader}
              onPageChange={(pageIndex) => onPageChangeHandler(pageIndex)}
              onSortedChange={(sortProperties) => onSortChange(sortProperties)}
            />
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserTable);
