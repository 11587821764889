import React from "react";
import UserWrapper from "../../assets/styledComponents/user.style";
import PageTitle from "components/common/PageTitle";
import UserPromoTable from "../../components/users/UserPromoTable";
import { connect } from "react-redux";

const UserPromo = props => {
    return (
        <UserWrapper {...props}>
            <PageTitle title="User" className="plr-15" iconClass="fas fa-user" />
            <div className="user-tbl">
                <UserPromoTable {...props} />
            </div>
        </UserWrapper>
    );
};

const mapStateToProps = state => {
    return {
        ...state.themeChanger
    };
};

export default connect(mapStateToProps, null)(UserPromo);