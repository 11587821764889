import { withFormik } from "formik";
import * as Yup from "yup";

const emailValidator = withFormik({
  validationSchema: Yup.object().shape({
    otp: Yup.string()
      .required("Please enter your OTP.")
      .matches(/^[0-9]+$/, "OTP must be only digits.")
      .min(6, "OTP must be exactly 6 digits.")
      .max(6, "OTP must be exactly 6 digits."),
  }),
  mapPropsToValues: (props) => ({
    otp: "",
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default emailValidator;
