import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import styled from "styled-components";
import { iconDemo } from "../../helper/constant";
import _ from "lodash";

const Wrapper = styled.div`
  .key {
    font-family: montserrat-bold;
    font-size: 16px;
    overflow-wrap: break-word;
  }
  .value {
    overflow-wrap: break-word;
  }
  .wrap-newline {
    white-space:pre-wrap;
  }
`;

const IMGURL = process.env.REACT_APP_S3URL+'order/original/';


const viewOrderModal = props => {

  const { modal, toggle, orderDetails } = props;
  let {user, bar, order_items} = orderDetails;
  let products = _.map(order_items, 'product');
  return (
    <Wrapper>
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <div>
            <h6>
              <strong>View Order</strong>
            </h6>
          </div>
        </ModalHeader>
        <ModalBody>
        {props.orderDetails.hasOwnProperty('id') ? (
            <Wrapper>
            <div className="row my-1">
              <div className="col-5 text-right key">Id</div>
              <div className="col-7 text-left value">{orderDetails.id}</div>
            </div>
            <div className="row">
              <div className="col-5 text-right key">Order Number</div>
              <div className="col-7 text-left value">
                {orderDetails.orderNo}
              </div>
            </div>
            <div className="row">
              <div className="col-5 text-right key">Restaurant Name</div>
              <div className="col-7 text-left value">
                {bar ? bar.restaurantName : null}
              </div>
            </div>
            <div className="row my-1">
              <div className="col-5 text-right key">Manager Name</div>
              <div className="col-7 text-left value">
              {bar ? bar.managerName : null}
              </div>
            </div>
            <div className="row my-1">
              <div className="col-5 text-right key">User Name</div>
              <div className="col-7 text-left value">{user ? user.fullName : null}</div>
            </div>
            <div className="row my-1">
              <div className="col-5 text-right key">Email-Address</div>
              <div className="col-7 text-left value">{user ? user.email : null}</div>
            </div>
            <div className="row">
              <div className="col-5 text-right key">Product Names</div>
              <div className="col-7 text-left wrap-newline value">{products.length > 0 ? products.map((p, i) =>  `${i+1}. ${p ? p.name : ' - '} \n`) : ' - '}</div>
            </div>
            <div className="row">
              <div className="col-5 text-right key">Sub Total</div>
              <div className="col-7 text-left value"><span>&#x24; </span>{(Math.round(orderDetails.subTotal * 100) / 100).toFixed(2)}</div>
            </div>
            <div className="row">
              <div className="col-5 text-right key">Promocode Amount</div>
              <div className="col-7 text-left value"><span>&#x24; </span>{(Math.round(orderDetails.promocode_amount * 100) / 100).toFixed(2)}</div>
            </div>
            <div className="row">
              <div className="col-5 text-right key">Transaction Fee</div>
              <div className="col-7 text-left value"><span>&#x24; </span>{(Math.round(orderDetails.transactionFee * 100) / 100).toFixed(2)}</div>
            </div>
            <div className="row">
              <div className="col-5 text-right key">Total</div>
              <div className="col-7 text-left value"><span>&#x24; </span>{(Math.round(orderDetails.total * 100) / 100).toFixed(2)}</div>
            </div>
          </Wrapper>
        ) : <Wrapper></Wrapper>}
        </ModalBody>
      </Modal>
    </Wrapper>
  );
};

export default viewOrderModal;
