import Pagination from "components/common/Pagination";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { changeMenuStatus, menuListings } from "../../../apis";
import item from "../../../assets/images/placeholder_image.png";
import UserWrapper from "../../../assets/styledComponents/user.style";
import { getPagination } from "../../../helper/constant";
import classes from "./Menu.module.css";
let debounceTimer;

const Menus = (props) => {
	console.log(props.history.location.state);
	const [tblData, settblData] = useState([]);
	const [loader, setLoader] = useState(true);
	const [activeStatus, setActiveStatus] = useState(false);
	const [done, setDone] = useState(false);
	const [statusId, setStatusId] = useState(null);
	const [pages, setPages] = useState(1);
	const [activePage, setActivePage] = useState(1);
	const [searchText, setSearchText] = useState(null);
	const [params, setParams] = useState({
		catId: props.history.location.state.catId,
		id: props.history.location.state.id,
		orderBy: "id",
		orderSequence: "ASC",
		pageNo: 1,
		pageSize: 24,
		search: "",
	});
	//const [toast, showToast] = useState(false);
	// const [toastMsg, setMsg] = useState("");
	const hasParam =
		props.history.location.state.hasOwnProperty("id") ||
		props.history.location.state.hasOwnProperty("catId");

	useEffect(() => {
		if (hasParam) {
			setParams({
				catId: props.history.location.state.catId,
				id: props.history.location.state.id,
				orderBy: "id",
				orderSequence: "ASC",
				pageNo: 1,
				pageSize: 24,
				search: "",
			});
		}
	}, []);
	useEffect(() => {
		if (searchText !== null) {
			if (debounceTimer) {
				clearTimeout(debounceTimer);
				debounceTimer = null;
			}
			debounceTimer = setTimeout(callSearchApi, 500);
		}
	}, [searchText]);

	useEffect(() => {
		menuListings(params).then((res) => {
			if (res && res.status) {
				settblData(res.data.list);
				setLoader(false);
				const pages = getPagination(res.data.totalRecords, params.pageSize);
				setPages(pages);
			}
		});
		return () => {
			if (debounceTimer) {
				clearTimeout(debounceTimer);
				debounceTimer = null;
			}
		};
	}, []);

	const getUserList = () => {
		// setLoader(true);
		menuListings(params).then((res) => {
			if (res && res.status) {
				settblData(res.data.list);
				setLoader(false);
				setActiveStatus(false);
				setDone(true);
				const pages = getPagination(res.data.totalRecords, params.pageSize);
				setPages(pages);
				setTimeout(() => setDone(false), 1000);
			}
		});
	};

	const onPageChangeHandler = (index) => {
		let page = index + 1;
		setActivePage(page);
		let data = {
			...params,
			pageNo: page,
		};
		setParams(data);
		setLoader(true);
		menuListings(data).then((res) => {
			if (res && res.status) {
				settblData(res.data.list);
				setLoader(false);
				const pages = getPagination(res.data.totalRecords, params.pageSize);
				setPages(pages);
			}
		});
	};

	const callSearchApi = () => {
		const data = {
			...params,
			search: searchText,
		};
		setParams(data);
		setLoader(true);
		menuListings(data).then((res) => {
			if (res && res.status) {
				settblData(res.data.list);
				setLoader(false);
				const pages = getPagination(res.data.totalRecords, params.pageSize);
				setPages(pages);
			}
		});
	};

	const onSortChange = (sortProperties) => {
		const orderByDirection = sortProperties[0].desc ? 1 : 0;
		if (!orderByDirection) {
			let data = {
				...params,
				orderBy: sortProperties[0].id,
				orderSequence: "DESC",
			};
			setParams(data);
			setLoader(true);
			menuListings(data).then((res) => {
				if (res && res.status) {
					settblData(res.data.list);
					setLoader(false);
					const pages = getPagination(res.data.totalRecords, params.pageSize);
					setPages(pages);
				}
			});
		} else {
			let data = {
				...params,
				orderSequence: "ASC",
			};
			setParams(data);
			setLoader(true);
			menuListings(data).then((res) => {
				if (res && res.status) {
					settblData(res.data.list);
					setLoader(false);
					const pages = getPagination(res.data.totalRecords, params.pageSize);
					setPages(pages);
				}
			});
		}
	};

	const activeInactiveStatusHandler = (id, barId) => {
		// setLoader(true);
		changeMenuStatus(id, barId).then((res) => {
			getUserList();
		});
	};
	const checkImage = (e) => {
		e.target.src = item;
	};
	// const columns = React.useMemo(
	//   () => [
	//     {
	//       Header: "Name",
	//       accessor: "name",
	//       className: "text-center",
	//       filterable: false,
	//       headerClassName: "react-table-header-class"
	//     },
	//     {
	//       Header: "Description",
	//       accessor: "description",
	//       className: "text-center",
	//       filterable: false,
	//       headerClassName: "react-table-header-class"
	//     },
	//     {
	//       Header: "Price",
	//       accessor: "price",
	//       className: "text-center",
	//       filterable: false,
	//       headerClassName: "react-table-header-class"
	//     },
	//     {
	//       Header: "Status",
	//       accessor: "status",
	//       className: "text-center",
	//       headerClassName: "react-table-header-class",
	//       sortable: false,
	//       filterable: false,
	//       width: 180,
	//       Cell: props => (
	//         <div className="react-action-class">
	//           {props.original.status === "Active" ? (
	//             <Fragment>
	//               <div id={`active-${props.original.id}`}>
	//                 <Button
	//                   style={{ minWidth: "125px" }}
	//                   className="c-btn c-info mr-10"
	//                   onClick={() =>
	//                     activeInactiveStatusHandler(props.original.id, props.original.barID)
	//                   }>
	//                   <div className="fs-14 medium-text">
	//                     <i className="fas fa-toggle-off mr-6" /> Active
	//                   </div>
	//                 </Button>
	//               </div>
	//               <RoyTooltip
	//                 id={`active-${props.original.id}`}
	//                 title={"Click to Deactivate"}
	//                 placement="left"></RoyTooltip>
	//             </Fragment>
	//           ) : (
	//             <RoyTooltip
	//               id={`deactive-${props.original.id}`}
	//               title={"Click to Active"}
	//               placement="left">
	//               <div id={`deactive-${props.original.id}`}>
	//                 <Button
	//                   style={{ minWidth: "125px" }}
	//                   className="c-btn c-warning mr-10"
	//                   onClick={() =>
	//                     activeInactiveStatusHandler(props.original.id)
	//                   }>
	//                   <div className="fs-14 medium-text">
	//                     <i className="fas fa-toggle-on mr-6" /> InActive
	//                   </div>
	//                 </Button>
	//               </div>
	//             </RoyTooltip>
	//           )}
	//         </div>
	//       ) // Custom cell components!
	//     },
	//     {
	//       Header: "Created Date",
	//       accessor: "createdAt",
	//       className: "text-center",
	//       filterable: false,
	//       sortable: true,
	//       width: 150,
	//       headerClassName: "react-table-header-class -sort",
	//       Cell: props => {
	//         return `${new Date(props.original.createdAt).getDate()}/${new Date(props.original.createdAt).getMonth() + 1}/${new Date(props.original.createdAt).getFullYear()}`;
	//       },
	//     }
	//   ],
	//   [params]
	// );

	return (
		<UserWrapper {...props}>
			{/* {toast && (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className="c-danger"
          message={toastMsg}
          onCloseCLick={() => showToast(false)}
        />
      )} */}
			<div className='pos-relative'>
				<div
					className='back-icon fs-15 demi-bold-text cursor-pointer'
					onClick={() => props.history.goBack()}>
					<i className='fas fa-step-backward'></i> Backward
				</div>
			</div>
			<div className='plr-15'>
				<div className='roe-card-style mtb-15'>
					<div className='roe-card-header module-header'>
						<div className='flex-1 fs-16 demi-bold-text'>
							<span className='hash'># </span>
							Menu
						</div>
						<div className='mr-10'>
							<input
								value={searchText ? searchText : ""}
								onChange={(e) => setSearchText(e.target.value)}
								type='text'
								placeholder='Search...'
								className='fs-14 medium-text plr-10 form-control react-form-input'
							/>
						</div>
					</div>

					<div className='roe-card-body'>
						<div className='roe-card-body'>
							{/* <ReactTable
                style={{
                  border: "none",
                  boxShadow: "none"
                }}
                data={tblData}
                columns={columns}
                defaultPageSize={2}
                minRows={0}
                pages={pages}
                page={activePage - 1}
                onPageChange={pageIndex => onPageChangeHandler(pageIndex)}
                filterable
                manual
                className="-striped -highlight custom-react-table-theme-class"
                PaginationComponent={Pagination}
                loading={loader}
                onSortedChange={sortProperties => onSortChange(sortProperties)}
              /> */}
							<p
								style={{ color: "#444", marginBottom: "30px" }}
								className='h4 font-weight-bold text-uppercase text-center'>
								{props.match.params.name}
							</p>
							{!loader ? (
								<>
									<div className={classes.wrapper}>
										{tblData && tblData.length ? (
											tblData.map((data) => (
												<div className={classes.container}>
													<div className={classes.image}>
														<img
															id='itemImage'
															onError={checkImage}
															src={data.avatar}
															alt='item'
														/>
													</div>
													<div className={classes.details}>
														<div>
															<p className={classes.title}>{data.name}</p>
															<span className={classes.location}>
																<i
																	className='fas fa-map-marker-alt'
																	style={{
																		fontSize: "14px",
																		marginRight: "10px",
																	}}></i>
																<p>
																	{data.pickup_location &&
																	data.pickup_location.address
																		? data.pickup_location.address
																		: " - "}
																</p>
															</span>
														</div>
														<div>
															<p className={classes.description}>
																{data.description
																	? data.description
																	: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nemo sunt qui eligendi? Quidem consectetur nihil delectus ducimus! Natus enim provident officia? Hic adipisci tempora delectus!"}
															</p>
														</div>
														<div>
															<p className={classes.title}>$ {data.price}</p>
															<div
																className='pretty p-switch p-fill'
																style={{ margin: "0", alignSelf: "flex-end" }}>
																<input
																	type='radio'
																	name={data.id}
																	checked={data.status === "Active"}
																	onClick={() => {
																		setStatusId(data.id);
																		setActiveStatus(true);
																		setDone(false);
																		activeInactiveStatusHandler(
																			data.id,
																			data.barID
																		);
																	}}
																/>
																<div className='state p-success'>
																	<label>&nbsp;</label>
																</div>
																{statusId && statusId === data.id ? (
																	<>
																		{activeStatus && (
																			<div
																				class='spinner-border spinner-border-sm'
																				style={{ color: "#28a745" }}
																				role='status'></div>
																		)}
																		{done && (
																			<i
																				className='fas fa-check'
																				style={{ color: "#28a745" }}></i>
																		)}{" "}
																	</>
																) : (
																	""
																)}
															</div>
														</div>
													</div>
												</div>
											))
										) : (
											<h6
												className='text-center'
												style={{ gridColumn: "span 2" }}>
												No menu!
											</h6>
										)}
									</div>
								</>
							) : (
								<div
									className='text-center'
									style={{
										marginTop: "5%",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<div
										class='spinner-border text-success'
										role='status'
										style={{ height: "20px", width: "20px" }}></div>
									<span
										className='visually-hidden'
										style={{ marginLeft: "10px" }}>
										Loading...
									</span>
								</div>
							)}

							{/* <div className={classes.container} >
                <div className={classes.image}><img src={item} alt="item" /></div>
                <div className={classes.details}>
                  <div>
                    <p className={classes.title}>Chocolate Protein Smoothie</p>
                    <i class="fas fa-trash-alt" style={{ color: "#e63946" }}></i>
                  </div>
                  <div>
                    <p className={classes.description}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Inventore odit cum nihil mollitia facilis autem accusantium facere. Deleniti quasi quod et sed ex ducimus possimus.</p>
                  </div>
                  <div>
                    <p className={classes.title}>$12.50</p>
                    <div class="pretty p-switch p-fill">
                      <input type="radio" name="switch1" />
                      <div class="state p-success">
                        <label>&nbsp;</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
						</div>
						{!loader && tblData.length ? (
							<Pagination
								pages={pages}
								page={activePage - 1}
								onPageChange={(pageIndex) => onPageChangeHandler(pageIndex)}
							/>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		</UserWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.themeChanger,
	};
};

export default connect(mapStateToProps, null)(Menus);
