import React, { useState, Fragment } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import CustomToast from "../customToast/CustomToast";
import ProfileImg from "../ProfileModal/profileImage/ProfileImage";

const AWS_USER_URL = process.env.REACT_APP_S3URL + "user/original/";

const UserForm = (props) => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
  } = props;
  const [toast, showToast] = useState(false);
  const [avatar, setAvatar] = useState();

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleSubmit = () => {
    props.handleSubmit();
    if (action === "edit") {
      const err = Object.getOwnPropertyNames(props.errors);
      if (props.isValid || !err.length) {
        let { values } = props;
        const submitData = {
          ...values,
          avatar: avatar ? avatar : values.avatar,
        };
        onSubmit(submitData, action);
      }
    }
    if (action === "add") {
      if (props.isValid) {
        let { values } = props;
        const subObj = {
          ...values,
          avatar: avatar ? avatar : values.avatar,
        };
        onSubmit(subObj, action);
      }
    }
  };

  const getImgObj = (imgObject) => {
    console.log("IMAGEOBJECT", imgObject);
    console.log(window.URL.createObjectURL(imgObject));

    var reader = new FileReader();
    reader.readAsDataURL(imgObject);
    reader.onload = function() {
      setAvatar(reader.result);
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  };

  return (
    <Fragment>
      {toast && (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className="c-danger"
          message="You haven't changed Anything"
          onCloseCLick={() => showToast(false)}
        />
      )}

      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Full Name<span className="c-text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="fullName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.fullName}
            placeholder="Full Name"
          />
          <Error field="fullName" />
        </div>

        <div className="form-group">
          <label className="fs-16 medium-text">
            Email<span className="c-text-danger">*</span>
          </label>
          <input
            type="email"
            className="form-control react-form-input"
            id="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            placeholder="Email"
          />
          <Error field="email" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Country Code<span className="c-text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="countryCode"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.countryCode}
            placeholder="Country Code"
          />
          <Error field="countryCode" />
        </div>

        <div className="form-group">
          <label className="fs-16 medium-text">
            Phone Number<span className="c-text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="mobile"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.mobile}
            placeholder="Phone Number"
          />
          <Error field="mobile" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Avatar</label>
          <ProfileImg
            className="text-left"
            getImgObj={getImgObj}
            url={values.avatar ? `${values.avatar}` : ""}
          />
        </div>
        <div>
          <button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default compose(enhancer)(UserForm);
