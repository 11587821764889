import {
    withFormik
} from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        fullName: Yup.string().required('This field is required'),
        mobile: Yup.number().required('This field is required'),
        email: Yup.string().required('This Field is required'),
        countryCode: Yup.number().min(5).required('This Field is required'),

    }),
    mapPropsToValues: props => ({
        fullName: props.editedData ? props.editedData.fullName : '',
        mobile: props.editedData ? props.editedData.mobile : '',
        email: props.editedData ? props.editedData.email : '',
        countryCode: props.editedData ? props.editedData.countryCode : '',
        avatar: props.editedData ? props.editedData.avatar : ""
    }),
    handleSubmit: (values) => { },
    displayName: 'CustomValidationForm',
    enableReinitialize: true,
});

export default formikEnhancer;