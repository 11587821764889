import React from "react";
import StyleWrapper from "../../../assets/styledComponents/user.style";
import PageTitle from "../../../components/common/PageTitle";
import DoucmentTable from "../DocumentManagement/DocumentTable"
import { connect } from "react-redux";

const Document = props => {
  return (
    <StyleWrapper {... props}>
      <PageTitle title="DOCUMENT" className="plr-15" iconClass="fas fa-list" />
      <div className="user-tbl">
        <DoucmentTable {...props} />
      </div>
    </StyleWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

// export default Document;
export default connect(mapStateToProps, null)(Document);
