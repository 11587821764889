import React from 'react';
import './backdrop.css'

const Backdrop = ( props ) => {
    return(
        props.show ?<div 
                        onClick={props.clickHandler}
                        className='Backdrop' ></div> : null
    )
}
export default Backdrop;