import React from "react";
import StyleWrapper from "../../assets/styledComponents/user.style";
import PageTitle from "components/common/PageTitle";
import OrderTable from "../../components/orders/OrderTable";
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";

const Order = props => {
    const history = useHistory();
    return (
        <StyleWrapper {...props}>
            <div className="pos-relative">
                <PageTitle
                    title="orders"
                    className="plr-15"
                    iconClass="fas fa-utensils"
                />
                <div
                    className="back-icon fs-15 demi-bold-text cursor-pointer"
                    onClick={() => history.goBack()}
                >
                    <i className="fas fa-step-backward"></i> Backward
                </div>
            </div>
            <div className="user-tbl">
                <OrderTable {...props} />
            </div>
        </StyleWrapper>
    );
};

const mapStateToProps = state => {
    return {
        ...state.themeChanger
    };
};

export default connect(mapStateToProps, null)(Order);
