import React from "react";
import FooterWrapper from "./footer.style";

const Footer = props => {
    return (
        <FooterWrapper {...props}>
            <div className="footerBack">
                <p>
                    Copyright &copy; 2021. All rights reserved.
                </p>
                {/* <li className="list-inline-item pull-right">
                        Handcrafted With{" "}
                        <i
                            style={{ color: "#e91e63" }}
                            className="fas fa-heart"
                        />
                    </li> */}

            </div>
        </FooterWrapper>
    );
};

export default Footer;
