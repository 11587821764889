import React, { useState, useEffect, Fragment } from "react";
import ReactTable from "react-table-6";
import Button from "components/button/Button";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import RoyTooltip from "components/common/RoyTooltip";
import Pagination from "components/common/Pagination";
import "react-table-6/react-table";
import swal from "sweetalert";
import { getPagination } from "../../helper/constant";
import { withRouter } from "react-router";
import {
  pageList,
  showPageDetails,
  changePageStatus,
  deleteStaticPage
} from "../../apis/index";

let debounceTimer;

const PageTable = props => {
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pages, setPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [params, setParams] = useState({
    orderBy: "createdAt",
    orderSequence: "ASC",
    pageNo: activePage,
    pageSize: 8,
    search: ""
  });
  const [staticPage, setStaticPageData] = useState({
    content: <span></span>,
    title: ""
  });
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const {
    sidebarTheme: { activeColor, textColor }
  } = props;

  useEffect(() => {
    if (searchText !== null) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
      debounceTimer = setTimeout(callSearchApi, 500);
    }
  }, [searchText]);

  useEffect(() => {
    pageList(params).then(res => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const totalPages = getPagination(
          res.data.totalCount,
          params.pageSize
        );
        setPages(totalPages);
      }
    });
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
    };
  }, []);

  const formAction = (action, data = null) => {
    if (action === "add") {
      props.history.push("/pages/add");
    } else if (action === "edit") {
      props.history.push(`/pages/edit/${data.id}`, {
        ...data
      });
    }
  };

  const getAllPages = () => {
    pageList(params).then(res => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
      }
    });
  };

  const callSearchApi = () => {
    const data = {
      ...params,
      search: searchText
    };
    setParams(data);
    pageList(data).then(res => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
      }
    });
  };

  const activeInactiveStatusHandler = id => {
    changePageStatus(id).then(res => {
      getAllPages();
    });
  };

  const deletePage = pageId => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      console.log(willDelete)
      if (willDelete) {
        deleteStaticPage(pageId).then(res => {
          if (res && res.status) {
            swal(res.message, {
              icon: "success"
            });
            pageList(params).then(res => {
              if (res && res.status) {
                settblData(res.data.list);
                setLoader(false);
                const totalPages = getPagination(
                  res.data.totalCount,
                  params.pageSize
                );
                setPages(totalPages);
              }
              if (res && !res.data.list.length) {
                onPageChangeHandler(params.pageNo - 2);
              }
            });
          } else {
            if (res) {
              swal(res.message, {
                icon: "danger"
              });
            }
          }
        });
      }
    });
  };

  const showStaticPage = data => {
    showPageDetails(data).then(res => {
      if (res && res.status) {
        let pageModalData = {
          content: res.data.content,
          title: res.data.title
        };
        setStaticPageData(pageModalData);
        setModal(true);
      }
    });
  };

  const onSortChange = sortProperties => {
    //API CALL for SortHandler
    const orderByDirection = sortProperties[0].desc ? 1 : 0;
    if (!orderByDirection) {
      setParams({
        ...params,
        orderBy: sortProperties[0].id,
        orderSequence: "DESC"
      });
      getAllPages();
    } else {
      setParams({
        ...params,
        orderSequence: "ASC"
      });
      getAllPages();
    }
  };

  const onPageChangeHandler = index => {
    let page = index + 1;
    setActivePage(page);
    let data = {
      ...params,
      pageNo: page
    };
    setParams(data);
    pageList(data).then(res => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
      }
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        className: "text-center",
        filterable: false,
        headerClassName: "react-table-header-class"
      },
      {
        Header: "Status",
        accessor: "status",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        filterable: false,
        width: 180,
        Cell: props => (
          <div className="react-action-class">
            {props.original.status === "Active" ? (
              <Fragment>
                <div id={`active-${props.original.id}`}>
                  <Button
                    style={{ minWidth: "125px" }}
                    className="c-btn c-info mr-10"
                    onClick={() =>
                      activeInactiveStatusHandler(props.original.id)
                    }>
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-off mr-6" /> Active
                    </div>
                  </Button>
                </div>
                <RoyTooltip
                  id={`active-${props.original.id}`}
                  title={"Click to Deactivate"}
                  placement="left"></RoyTooltip>
              </Fragment>
            ) : (
                <RoyTooltip
                  id={`deactive-${props.original.id}`}
                  title={"Click to Active"}
                  placement="left">
                  <div id={`deactive-${props.original.id}`}>
                    <Button
                      style={{ minWidth: "125px" }}
                      className="c-btn c-warning mr-10"
                      onClick={() =>
                        activeInactiveStatusHandler(props.original.id)
                      }>
                      <div className="fs-14 medium-text">
                        <i className="fas fa-toggle-on mr-6" /> InActive
                    </div>
                    </Button>
                  </div>
                </RoyTooltip>
              )}
          </div>
        ) // Custom cell components!
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        filterable: false,
        width: 300,
        Cell: props => (
          <div className="react-action-class">
            <Button
              className="c-btn c-success mr-10"
              onClick={() => showStaticPage(props.original.id)}>
              <div className="fs-14 medium-text">
                <i className="fas fa-eye mr-6" /> View
              </div>
            </Button>

            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction("edit", props.original)}>
              <div className="fs-14 medium-text">
                <i className="fas fa-edit mr-6" /> Edit
              </div>
            </Button>
            <Button
              className="c-btn c-danger"
              onClick={() => deletePage(props.original.id)}>
              <div className="fs-14 medium-text">
                <i className="fas fa-trash mr-6" /> Delete
              </div>
            </Button>
          </div>
        ) // Custom cell components!
      }
    ],
    [params]
  );

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{staticPage.title}</ModalHeader>
        <ModalBody>
          <p dangerouslySetInnerHTML={{ __html: staticPage.content }}></p>
        </ModalBody>
        <ModalFooter>
          <Button className="c-btn c-info mr-10" onClick={toggle}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Pages
            </div>
            <div className="mr-10">
              <input
                value={searchText ? searchText : ""}
                onChange={e => setSearchText(e.target.value)}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            <div>
              <Button
                className="c-btn ma-5"
                onClick={() => formAction("add")}
                style={{
                  backgroundColor: activeColor,
                  color: textColor
                }}>
                <i className="fas fa-plus mr-10"></i>Add Page
              </Button>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              filterable
              manual
              pages={pages}
              className="-striped -highlight custom-react-table-theme-class"
              page={activePage - 1}
              PaginationComponent={Pagination}
              loading={loader}
              minRows={0}
              onPageChange={pageIndex => onPageChangeHandler(pageIndex)}
              onSortedChange={sortProperties => onSortChange(sortProperties)}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PageTable);
