import React from "react";
import UserWrapper from "../../assets/styledComponents/user.style";
import PageTitle from "components/common/PageTitle";
import BarPromoTable from "../../components/bar/BarPromoTable";
import { connect } from "react-redux";

const BarPromo = props => {
    return (
        <UserWrapper {...props}>
            <div className="pos-relative">
                <PageTitle title="Venues" className="plr-15" iconClass="fas fa-user" />
                <div
                    className="back-icon fs-15 demi-bold-text cursor-pointer"
                    onClick={() => props.history.goBack()}
                >
                    <i className="fas fa-step-backward"></i> Backward
                </div>
            </div>
            <div className="user-tbl">
                <BarPromoTable {...props} />
            </div>
        </UserWrapper>
    );
};

const mapStateToProps = state => {
    return {
        ...state.themeChanger
    };
};

export default connect(mapStateToProps, null)(BarPromo);