import React, { useState, Fragment } from "react";
import enhancer from "./validator";
// import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.css";
import CustomToast from "../customToast/CustomToast";
import CKEditor from "ckeditor4-react";
import { ckConfig } from "../../helper/constant";

const PageForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action
  } = props;

  const [toast, showToast] = useState(false);
  const [editorState, setEditorState] = useState("");

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    let { values, handleSubmit } = props;
    let data = {
      title: values.title,
      content: editorState || values.content
    };
    onSubmit(data, action);
    handleSubmit();
  };

  return (
    <Fragment>
      {toast && (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className="c-danger"
          message="You haven't changed Anything"
          onCloseCLick={() => showToast(false)}
        />
      )}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="fs-16 medium-text">Title</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="title"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            placeholder="Enter Title"
          />
          <Error field="title" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Content</label>
          <CKEditor
            id="content"
            onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
            config={ckConfig}
            data={values.content}
            onChange={event => {
              setEditorState(event.editor.getData());
            }}
          />
        </div>
        <div>
          <button
            type="submit"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}>
            Submit
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default enhancer(PageForm);
