import React, { useState, useEffect } from "react";
import ReactTable from "react-table-6";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import ViewOrderModal from "./viewOrderModal";
import NumberFormat from 'react-number-format';
import "react-table-6/react-table.css";
import { withRouter, useHistory } from "react-router";
import { venueOrderList } from "../../apis/index";
import { getPagination } from "../../helper/constant";
let debounceTimer;

const VenueTable = (props) => {
	const history = useHistory();
	const [tblData, settblData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [pages, setPages] = useState(1);
	const [activePage, setActivePage] = useState(1);
	const [searchText, setSearchText] = useState(null);
	const [params, setParams] = useState({
		isExport: false,
		orderBy: "id",
		orderSequence: "DESC",
		pageNo: 1,
		pageSize: 25,
		search: "",
	});
	// const [orderDetails, setOrderDetails] = useState({});
	const [modal, setModal] = useState(false);
	// const [DocModal, setDocModal] = useState(false);
	// const [doc, setDoc] = useState({
	// 	id: 0,
	// 	list: [],
	// });

	const toggle = () => setModal(!modal);

	const {
		sidebarTheme: { activeColor, textColor },
	} = props;

	useEffect(() => {
		if (searchText !== null) {
			if (debounceTimer) {
				clearTimeout(debounceTimer);
				debounceTimer = null;
			}
			debounceTimer = setTimeout(callSearchApi, 500);
		}
	}, [searchText]);

	useEffect(() => {
		setLoader(true);
		venueOrderList(params).then((res) => {
			if (res && res.status) {
				settblData(res.data.list);
				setLoader(false);
				const pages = getPagination(res.data.totalRecords, params.pageSize);
				setPages(pages);
			}
		});
		return () => {
			if (debounceTimer) {
				clearTimeout(debounceTimer);
				debounceTimer = null;
			}
		};
	}, []);

	const convertArrayOfObjectsToCSV = (args) => {
		let result, ctr, keys, columnDelimiter, lineDelimiter, data;
		data = args.data || null;
		if (data == null || !data.length) {
			return null;
		}

		columnDelimiter = ",";
		lineDelimiter = "\n";

		keys = Object.keys(data[0]);

		result = "";
		result += keys.join(columnDelimiter);
		result += lineDelimiter;

		data.forEach((item) => {
			ctr = 0;
			keys.forEach(function (key) {
				if (ctr > 0) result += columnDelimiter;

				result += item[key];
				ctr++;
			});
			result += lineDelimiter;
		});
		return result;
	};

	const exportToCSV = () => {
		venueOrderList({
			isExport: true,
			orderBy: "id",
			orderSequence: "DESC",
			pageNo: 1,
			pageSize: 8000000,
			search: "",
		})
			.then((res) => {
				if (res && res.status) {
					var data, filename, link;

					var csv = convertArrayOfObjectsToCSV({
						data: res.data.list,
					});

					if (csv == null) return;

					filename = "venue_orders.csv";

					if (!csv.match(/^data:text\/csv/i)) {
						csv = "data:text/csv;charset=utf-8," + csv;
					}
					data = encodeURI(csv);

					link = document.createElement("a");
					link.setAttribute("href", data);
					link.setAttribute("download", filename);
					link.click();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const onPageChangeHandler = (index) => {
		let page = index + 1;
		setActivePage(page);
		let data = {
			...params,
			pageNo: page,
		};
		setParams(data);
		setLoader(true);
		venueOrderList(data).then((res) => {
			if (res && res.status) {
				settblData(res.data.list);
				setLoader(false);
				const pages = getPagination(res.data.totalRecords, params.pageSize);
				setPages(pages);
			}
		});
	};

	const callSearchApi = () => {
		const data = {
			...params,
			search: searchText,
		};
		setParams(data);
		setLoader(true);
		venueOrderList(data).then((res) => {
			if (res && res.status) {
				settblData(res.data.list);
				setLoader(false);
				const pages = getPagination(res.data.totalRecords, params.pageSize);
				setPages(pages);
			}
		});
	};

	// const showOneOrder = (data) => {
	// 	showOrderDetails(data).then((res) => {
	// 		if (res && res.status) {
	// 			setOrderDetails(res.data);
	// 			setModal(true);
	// 		} else {
	// 		}
	// 	});
	// };

	const onSortChange = (sortProperties) => {

		const orderByDirection = sortProperties[0].desc ? 1 : 0;
		if (!orderByDirection) {
			let data = {
				...params,
				orderBy: sortProperties[0].id,
				orderSequence: "DESC",
			};
			setParams(data);
			setLoader(true);
			venueOrderList(data).then((res) => {
				if (res && res.status) {
					settblData(res.data.list);
					setLoader(false);
					const pages = getPagination(res.data.totalRecords, params.pageSize);
					setPages(pages);
				}
			});
		} else {
			let data = {
				...params,
				orderSequence: "ASC",
			};
			setParams(data);
			setLoader(true);
			venueOrderList(data).then((res) => {
				if (res && res.status) {
					settblData(res.data.list);
					setLoader(false);
					const pages = getPagination(res.data.totalRecords, params.pageSize);
					setPages(pages);
				}
			});
		}
	};

	const columns = React.useMemo(
		() => [
			{
				Header: "Venue",
				accessor: "restaurantName",
				// className: "text-center",
				filterable: false,
				sortable: true,
				headerClassName: "react-table-header-class",
				Cell: (props) => <p style={{ fontWeight: "600", whiteSpace: "pre-wrap", textAlign: "initial" }}>{props.original.restaurantName}</p>
			},
			// {
			// 	Header: "Current Orders",
			// 	accessor: "currentOrders",
			// 	className: "text-center",
			// 	filterable: false,
			// 	sortable: false,
			// 	headerClassName: "react-table-header-class",
			// },
			{
				Header: "Successful Orders",
				accessor: "pickedUpOrders",
				className: "text-center",
				filterable: false,
				sortable: false,
				headerClassName: "react-table-header-class",
			},
			{
				Header: "Not PickedUp Orders",
				accessor: "notPickedUpOrders",
				className: "text-center",
				width: 180,
				filterable: false,
				sortable: false,
				headerClassName: "react-table-header-class",
			},
			// {
			// 	Header: "Success Orders",
			// 	accessor: "successPaymentOrders",
			// 	className: "text-center",
			// 	filterable: false,
			// 	sortable: false,
			// 	headerClassName: "react-table-header-class",
			// },
			{
				Header: "Refunded Orders",
				accessor: "refundedPaymentOrders",
				className: "text-center",
				filterable: false,
				sortable: false,
				headerClassName: "react-table-header-class",
			},
			{
				Header: "Failed Orders",
				accessor: "failedPaymentOrders",
				className: "text-center",
				filterable: false,
				sortable: false,
				headerClassName: "react-table-header-class",
			},
			{
				Header: "Total Orders",
				accessor: "totalOrders",
				className: "text-center",
				filterable: false,
				sortable: false,
				headerClassName: "react-table-header-class",
				// Cell: (props) => parseInt(props.original.failedPaymentOrders) + parseInt(props.original.notPickedUpOrders) + parseInt(props.original.pickedUpOrders) + parseInt(props.original.refundedPaymentOrders)
			},
			{
				Header: "Total Revenue",
				accessor: "grossAmount",
				className: "text-center",
				filterable: false,
				sortable: false,
				headerClassName: "react-table-header-class",
				Cell: (props) => <NumberFormat style={{ letterSpacing: "1px", fontWeight: "600" }} value={props.original.grossAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} />
			},
			{
				Header: "Action",
				accessor: "action",
				className: "text-center",
				headerClassName: "react-table-header-class",
				sortable: false,
				filterable: false,
				width: 150,
				Cell: (props) => (
					<div className="react-action-class">
						<Button
							className="c-btn c-success mr-10"
							onClick={() =>
								history.push({
									pathname: "/venue-orders/order",
									state: { barId: props.original.id },
								})
							}
						>
							<div className="fs-14 medium-text">
								<i className="fas fa-eye mr-6" /> View Orders
							</div>
						</Button>
					</div>
				),
			},
		],
		[params]
	);

	return (
		<React.Fragment>
			<ViewOrderModal
				orderDetails={""}
				toggle={toggle}
				modal={modal}
			/>
			<div className="plr-15">
				<div className="roe-card-style mtb-15">
					<div className="roe-card-header module-header">
						<div className="flex-1 fs-16 demi-bold-text">
							<span className="hash"># </span> Order
						</div>
						<div className="mr-10">
							<input
								value={searchText ? searchText : ""}
								onChange={(e) => setSearchText(e.target.value)}
								type="text"
								placeholder="Search..."
								className="fs-14 medium-text plr-10 form-control react-form-input"
							/>
						</div>
						{/* <div>
                            <Button
                                className="c-btn ma-5"
                                onClick={() => formAction("add")}
                                style={{
                                    backgroundColor: activeColor,
                                    color: textColor
                                }}>
                                <i className="fas fa-plus mr-10"></i>Add Bar
                            </Button>
                        </div> */}
						<div>
							<Button
								className="c-btn ma-5 c-warning"
								onClick={() => exportToCSV()}
								style={{
									backgroundColor: activeColor,
									color: textColor,
								}}
							>
								<i className="fas fa-file-export mr-10"></i>Export
							</Button>
						</div>
					</div>
					<div className="roe-card-body">
						<ReactTable
							style={{
								border: "none",
								boxShadow: "none",
							}}
							data={tblData}
							columns={columns}
							defaultPageSize={2}
							minRows={0}
							pages={pages}
							filterable
							manual
							className="-striped -highlight custom-react-table-theme-class"
							page={activePage - 1}
							PaginationComponent={Pagination}
							loading={loader}
							onPageChange={(pageIndex) => onPageChangeHandler(pageIndex)}
							onSortedChange={(sortProperties) => onSortChange(sortProperties)}
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default withRouter(VenueTable);
