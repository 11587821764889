import React, { useState, useEffect, Fragment } from "react";
import ReactTable from "react-table-6";
import Button from "components/button/Button";
import RoyTooltip from "components/common/RoyTooltip";
import Pagination from "components/common/Pagination";
import { iconDemo } from "helper/constant";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";


import "react-table-6/react-table.css";
import swal from 'sweetalert';
import { withRouter } from "react-router";
import {
    categoryList,
    showCategoryDetails,
    changeCategoryStatus,
    deleteCategory,
    verifyAccount
} from "../../apis/index";
import { getPagination } from "../../helper/constant";

let debounceTimer;

const CategoryTable = props => {
    const [tblData, settblData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [pages, setPages] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [searchText, setSearchText] = useState(null);
    const [params, setParams] = useState({
        isExport: false,
        orderBy: "id",
        orderSequence: "DESC",
        pageNo: 1,
        pageSize: 25,
        search: ""
    });
    const [userDetails, setUserDetails] = useState({});
    const [modal, setModal] = useState(false);
    const [DocModal, setDocModal] = useState(false);
    const [doc, setDoc] = useState({
        id: 0,
        list: []
    });

    const toggleDoc = () => setDocModal(!DocModal);
    const toggle = () => setModal(!modal);

    const {
        sidebarTheme: { activeColor, textColor }
    } = props;

    useEffect(() => {
        if (searchText !== null) {
            if (debounceTimer) {
                clearTimeout(debounceTimer);
                debounceTimer = null;
            }
            debounceTimer = setTimeout(callSearchApi, 500);
        }
    }, [searchText]);

    useEffect(() => {
        categoryList(params).then(res => {
            if (res && res.status) {
                settblData(res.data.list);
                setLoader(false);
                const pages = getPagination(res.data.totalCount, params.pageSize);
                setPages(pages);
            }
        });
        return () => {
            if (debounceTimer) {
                clearTimeout(debounceTimer);
                debounceTimer = null;
            }
        };
    }, []);

    const convertArrayOfObjectsToCSV = (args) => {
        let result, ctr, keys, columnDelimiter, lineDelimiter, data;
        data = args.data || null;
        if (data == null || !data.length) {
            return null;
        }

        columnDelimiter = ',';
        lineDelimiter = '\n';

        keys = Object.keys(data[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach((item) => {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });
        return result;
    };

    const exportToCSV = () => {

        categoryList({
            isExport: true,
            orderBy: "id",
            orderSequence: "DESC",
            pageNo: 1,
            pageSize: 25,
            search: ""
        }).then(res => {
            if (res && res.status) {
                var data, filename, link;

                var csv = convertArrayOfObjectsToCSV({
                    data: res.data.list
                });

                if (csv == null) return;

                filename = 'category.csv';

                if (!csv.match(/^data:text\/csv/i)) {
                    csv = 'data:text/csv;charset=utf-8,' + csv;
                }
                data = encodeURI(csv);

                link = document.createElement('a');
                link.setAttribute('href', data);
                link.setAttribute('download', filename);
                link.click();
            }
        }).catch(err => {
            console.log(err);
        });
    };

    const getUserList = () => {
        // setLoader(true);
        categoryList(params).then(res => {
            if (res && res.status) {
                settblData(res.data.list);
                setLoader(false);
                const pages = getPagination(res.data.totalCount, params.pageSize);
                setPages(pages);
            }
        });
    };

    const onPageChangeHandler = index => {
        let page = index + 1;
        setActivePage(page);
        let data = {
            ...params,
            pageNo: page
        };
        setParams(data);
        setLoader(true);
        categoryList(data).then(res => {
            if (res && res.status) {
                settblData(res.data.list);
                setLoader(false);
                const pages = getPagination(res.data.totalCount, params.pageSize);
                setPages(pages);
            }
        });
    };

    const callSearchApi = () => {
        const data = {
            ...params,
            search: searchText
        };
        setParams(data);
        setLoader(true);
        categoryList(data).then(res => {
            if (res && res.status) {
                settblData(res.data.list);
                setLoader(false);
                const pages = getPagination(res.data.totalCount, params.pageSize);
                setPages(pages);
            }
        });
    };

    const activeInactiveStatusHandler = id => {
        setLoader(true);
        changeCategoryStatus(id).then(res => {
            getUserList();
        });
    };

    const onSortChange = sortProperties => {
        const orderByDirection = sortProperties[0].desc ? 1 : 0;
        if (!orderByDirection) {
            let data = {
                ...params,
                orderBy: sortProperties[0].id,
                orderSequence: "DESC"
            };
            setParams(data);
            setLoader(true);
            categoryList(data).then(res => {
                if (res && res.status) {
                    settblData(res.data.list);
                    setLoader(false);
                    const pages = getPagination(res.data.totalCount, params.pageSize);
                    setPages(pages);
                }
            });
        } else {
            let data = {
                ...params,
                orderSequence: "ASC"
            };
            setParams(data);
            setLoader(true);
            categoryList(data).then(res => {
                if (res && res.status) {
                    settblData(res.data.list);
                    setLoader(false);
                    const pages = getPagination(res.data.totalCount, params.pageSize);
                    setPages(pages);
                }
            });
        }
    };

    const formAction = (action, data = null) => {
        if (action === "add") {
            props.history.push("/category/add");
        } else if (action === "edit") {
            console.log("data", data);

            props.history.push(`/category/edit/${data.id}`, {
                ...data
            });
        }
    };

    const deletePage = pageId => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record!",
            icon: "warning",
            buttons: true,
            dangerMode: true
        }).then(willDelete => {
            if (willDelete) {
                deleteCategory(pageId).then(res => {
                    if (res && res.status) {
                        swal(res.message, {
                            icon: "success"
                        });
                        setLoader(true);
                        categoryList(params).then(res => {
                            if (res && res.status) {
                                settblData(res.data.list);
                                setLoader(false);
                                const pages = getPagination(
                                    res.data.totalCount,
                                    params.pageSize
                                );
                                setPages(pages);
                            }
                            if (res && !res.data.list.length) {
                                onPageChangeHandler(params.pageNo - 1);
                            }
                        });
                        getUserList();
                    } else {
                        if (res) {
                            swal(res.message, {
                                icon: "danger"
                            });
                        }
                    }
                });
            }
        });
    };


    const verifyAccountDoc = (status, id) => {
        verifyAccount({
            isVerified: status,
            id: id
        }).then(res => {
            if (res && res.status) {
                toggleDoc(false);
                getUserList();
            }
        });
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                className: "text-center",
                filterable: false,
                sortable: false,
                headerClassName: "react-table-header-class"
            },
            {
                Header: "Parent",
                accessor: "category.name",
                className: "text-center",
                filterable: false,
                sortable: false,
                headerClassName: "react-table-header-class"
            },
            {
                Header: "Status",
                accessor: "status",
                className: "text-center",
                headerClassName: "react-table-header-class",
                filterable: false,
                width: 140,
                Cell: props => (
                    <div className="react-action-class">
                        {props.original.status === "Active" ? (
                            <Fragment>
                                <div id={`active-${props.original.id}`}>
                                    <Button
                                        style={{ minWidth: "125px" }}
                                        className="c-btn c-info mr-10"
                                        onClick={() =>
                                            activeInactiveStatusHandler(props.original.id)
                                        }>
                                        <div className="fs-14 medium-text">
                                            <i className="fas fa-toggle-off mr-6" /> Active
                                        </div>
                                    </Button>
                                </div>
                                <RoyTooltip
                                    id={`active-${props.original.id}`}
                                    title={"Click to Deactivate"}
                                    placement="left"></RoyTooltip>
                            </Fragment>
                        ) : (
                            <RoyTooltip
                                id={`deactive-${props.original.id}`}
                                title={"Click to Active"}
                                placement="left">
                                <div id={`deactive-${props.original.id}`}>
                                    <Button
                                        style={{ minWidth: "125px" }}
                                        className="c-btn c-warning mr-10"
                                        onClick={() =>
                                            activeInactiveStatusHandler(props.original.id)
                                        }>
                                        <div className="fs-14 medium-text">
                                            <i className="fas fa-toggle-on mr-6" /> InActive
                                        </div>
                                    </Button>
                                </div>
                            </RoyTooltip>
                        )}
                    </div>
                )
            },
            {
                Header: "Action",
                accessor: "action",
                className: "text-center",
                headerClassName: "react-table-header-class",
                sortable: false,
                filterable: false,
                width: 440,
                Cell: props => (
                    <div className="react-action-class">
                        <Button
                            className="c-btn c-info mr-10"
                            onClick={() => formAction("edit", props.original)}>
                            <div className="fs-14 medium-text">
                                <i className="fas fa-edit mr-6" /> Edit
                            </div>
                        </Button>

                        <Button
                            className="c-btn c-danger mr-8"
                            onClick={() => deletePage(props.original.id)}>
                            <div className="fs-14 medium-text">
                                <i className="fas fa-trash mr-6" /> Delete
                            </div>
                        </Button>
                    </div>
                )
            },
            {
                Header: "Created Date",
                accessor: "createdAt",
                className: "text-center",
                filterable: false,
                sortable: true,
                width: 100,
                headerClassName: "react-table-header-class -sort",
                Cell: props => {
                    return `${new Date(props.original.createdAt).getDate()}/${new Date(props.original.createdAt).getMonth() + 1}/${new Date(props.original.createdAt).getFullYear()}`;
                },
            }
        ],
        [params]
    );

    return (
        <React.Fragment>
            <div className="plr-15">
                <div className="roe-card-style mtb-15">
                    <div className="roe-card-header module-header">
                        <div className="flex-1 fs-16 demi-bold-text">
                            <span className="hash"># </span> Category
                        </div>
                        <div className="mr-10">
                            <input
                                value={searchText ? searchText : ""}
                                onChange={e => setSearchText(e.target.value)}
                                type="text"
                                placeholder="Search..."
                                className="fs-14 medium-text plr-10 form-control react-form-input"
                            />
                        </div>
                        <div>
                            <Button
                                className="c-btn ma-5"
                                onClick={() => formAction("add")}
                                style={{
                                    backgroundColor: activeColor,
                                    color: textColor
                                }}>
                                <i className="fas fa-plus mr-10"></i>Add Category
                            </Button>
                        </div>
                        <div>
                            <Button
                                className="c-btn ma-5 c-warning"
                                onClick={() => exportToCSV()}
                                style={{
                                    backgroundColor: activeColor,
                                    color: textColor
                                }}>
                                <i className="fas fa-file-export mr-10"></i>Export
                            </Button>
                        </div>
                    </div>
                    <div className="roe-card-body">
                        <ReactTable
                            style={{
                                border: "none",
                                boxShadow: "none"
                            }}
                            data={tblData}
                            columns={columns}
                            defaultPageSize={2}
                            minRows={0}
                            pages={pages}
                            filterable
                            manual
                            className="-striped -highlight custom-react-table-theme-class"
                            page={activePage - 1}
                            PaginationComponent={Pagination}
                            loading={loader}
                            onPageChange={pageIndex => onPageChangeHandler(pageIndex)}
                            onSortedChange={sortProperties => onSortChange(sortProperties)}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(CategoryTable);
