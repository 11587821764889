import React, { useState } from "react";
import HeaderWrapper from "./header.style";
import IntlMessages from "util/intlMessages";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { userDemo } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { AppName } from "helper/constant";
import { logout } from "../../redux/auth/actions";
import { signOut } from "apis/index";
import CustomToast from "../customToast/CustomToast";
import Modal from "../changePassword/modal";

const Header = (props) => {
  const [profilePopover, setProfilePopover] = useState(false);
  const [modal, viewModal] = useState(false);
  const [toast, showToast] = useState(false);
  //const [companyDetails, setCompanyDetails] = useState({});
  const { drawerMiniMethod, mini } = props;
  const userSignout = () => {
    signOut().then((res) => {
      if (res && res.status) {
        props.logout();
      } else {
        props.logout();
      }
    });
  };

  const profilePopovertoggle = () => {
    setProfilePopover(!profilePopover);
  };

  const showModal = () => {
    viewModal(true);
  };
  const closeModal = () => {
    viewModal(false);
  };
  const showSuccess = () => {
    viewModal(false);
    showToast(true);
  };
  let Alert = <span></span>;

  if (toast) {
    Alert = (
      <CustomToast
        heading={"Alert!"}
        width={400}
        show="bottomRight"
        transition
        position="bottom-right"
        className="c-success"
        message="Password changed successfully!"
        onCloseCLick={() => showToast(false)}
      />
    );
  }
  let ChangePassModal = <span></span>;
  if (modal) {
    ChangePassModal = (
      <Modal
        show
        closeModal={closeModal}
        showSuccess={showSuccess}
        isSuper={props.isSuper}
      />
    );
  }

  return (
    <HeaderWrapper {...props}>
      {Alert}
      {ChangePassModal}
      {/* <EditProfile
                companyDetails={companyDetails}
                modal={EditProfileModal}
                toggle={editProfileModalToggle}
            /> */}
      <div className="headerBack">
        <ul className="list-inline ma-0">
          <li className="list-inline-item feed-text-area-icon">
            <div className="drawer-handle-arrow">
              {mini ? (
                <button
                  className="top-header-icon c-btn"
                  onClick={drawerMiniMethod()}
                >
                  <i className="fas fa-arrow-right" />
                </button>
              ) : (
                <button
                  className="top-header-icon c-btn"
                  onClick={drawerMiniMethod()}
                >
                  <i className="fas fa-arrow-left" />
                </button>
              )}
            </div>
            <div className="mini-drawer-menu-icon" onClick={drawerMiniMethod()}>
              <i className="fas fa-bars" />{" "}
              <span className="app-name">{AppName}</span>
            </div>
          </li>
          <li className="list-inline-item feed-text-area-icon pull-right">
            <img
              id="profilePopover"
              onClick={profilePopovertoggle}
              className="top-header-profile-class"
              src={userDemo}
              alt="notify"
            />
          </li>
        </ul>

        {/* Profile Popover */}

        <Popover
          className="language-popover-width"
          placement="bottom"
          isOpen={profilePopover}
          target="profilePopover"
          toggle={profilePopovertoggle}
          trigger="hover"
        >
          <PopoverHeader className="custom-popover-header ">
            <ul className="list-inline ma-0">
              <li className="list-inline-item notification-popover-profile">
                <img src={userDemo} alt="notify" />
              </li>
              <li className="list-inline-item notification-popover-profile">
                Admin
              </li>
            </ul>
          </PopoverHeader>
          <PopoverBody className="pa-0">
            <div className="container-fluid grid-popover pa-0">
              {/* <ul className="list-inline plr-10 ma-0 language-list-hover">
                                <li className="list-inline-item pa-5">
                                    <i className="fas fa-user-alt" />
                                </li>
                                <li className="list-inline-item pa-5">
                                    <IntlMessages id="header.editprofile" />
                                </li>
                            </ul> */}

              <ul
                className="list-inline plr-10 language-list-hover ma-0"
                onClick={showModal}
              >
                <li className="list-inline-item pa-5">
                  <i className="fas fa-cog" />
                </li>
                <li className="list-inline-item pa-5">Change Password</li>
              </ul>
              <ul
                className="list-inline plr-10 language-list-hover ma-0"
                onClick={userSignout}
              >
                <li className="list-inline-item pa-5">
                  <i className="fas fa-sign-out-alt" />
                </li>
                <li className="list-inline-item pa-5">
                  <IntlMessages id="header.signout" />
                </li>
              </ul>
            </div>
          </PopoverBody>
        </Popover>

        {/* Profile popover */}
      </div>
    </HeaderWrapper>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};
export default compose(withRouter, connect(null, mapDispatchToProps))(Header);
