import { LOGIN, LOGOUT } from './authActions';

export const login = (data) => {
    console.log(data)
    return {
        type: LOGIN,
        isLogin: true,
        accessToken: data.accessToken
    };
}
export const logout = () => {
    return {
        type: LOGOUT,
        isLogin: false
    }
}