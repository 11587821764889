import styled from "styled-components";

const PageManagementWrapper = styled.div`

.net_status {
    position: relative !important;
    transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s !important;
    border: 1px solid transparent !important;
    font-size: 0.8rem !important;
    font-weight: 500 !important;
    outline: none !important;
    display: inline-block !important;
    text-align: center !important;
    white-space: nowrap !important;
    line-height: 1.5 !important;
    padding: 0.375rem 0.75rem !important;
    border-radius: 6px;
  }
  .net_inactive {
    color : #212529;
    background-color : #d92550;
    border-color : #d92550;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12) !important;
  }
  .net_active {
    color : #fff;
    background-color :#00bcd4;
    border-color : #00bcd4;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12) !important;
  }



    .module-header {
        display: flex;
        align-items: center;
    }

    .custom-react-table-theme-class {
        .rt-tbody {
            .rt-td {
                font-family: "montserrat-medium";
                color: #757575;
            }
        }
    }

    .Table__itemCount {
        font-size: 14px;
    }

    .Table__pagination {
        display: flex;
        justify-content: flex-end;
        padding: 20px 10px 0px;
    }

    .Table__pageButton {
        font-size: 18px;
        outline: none;
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: #757575 !important;
        margin: 0 5px;
    }

    .Table__pageButton:disabled {
        cursor: not-allowed;
        color: gray;
    }

    .Table__pageButton--active {
        font-weight: bold;
        background: ${props => props.sidebarTheme.activeColor};
        color: ${props => props.sidebarTheme.textColor} !important;
        width: 30px;
        height: 30px;
        border-radius: 6px;
    }

    .tabl-search {
        &:focus {
            outline: 0;
        }
    }

    .back-icon {
        position: absolute;
        right: 15px;
        bottom: 20px;
        color: ${props => props.sidebarTheme.activeColor};
    }

    .-sort-desc {
        box-shadow: none !important;
        &:before {
            content: "▼";
            float: right;
            margin-right: 14px;
            color: ${props => props.sidebarTheme.activeColor};
        }
    }

    .-sort-asc {
        box-shadow: none !important;
        &:before {
            content: "▲";
            float: right;
            margin-right: 14px;
            color: ${props => props.sidebarTheme.activeColor};
        }
    }
`;

export default PageManagementWrapper;
