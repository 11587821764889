import styled from "styled-components";

export const ModalWrapper = styled.div`
  .Modal {
    z-index: 200;
    position: fixed;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
    transition: all 0.3s ease-out;
    width: 500px !important;
    .otpInputWrapper {
      width: 100%;
      input {
        width: 42px !important;
        height: 42px !important;
        margin: 0 10px;
        font-family: "montserrat-medium";
        font-size: 18px;
        border-radius: 6px !important;
        border: 0px;
        border: 1px solid #ddd !important;
        outline: none;
        color: #495057 !important;
      }
    }
  }
`;
