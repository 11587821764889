import React, { useEffect, useState } from "react";
import UserWrapper from "../../assets/styledComponents/user.style";
import PageTitle from "components/common/PageTitle";
import BarForm from "components/bar/BarForm";
import CustomToast from "../../components/customToast/CustomToast";
import { addBar, editBar } from "../../apis/index";

import { connect } from "react-redux";

const BarEdit = props => {
  const [editedData, setEditedData] = useState(null);
  const [toast, showToast] = useState(false);
  const [toastMsg, setMsg] = useState("");
  const hasParam = props.match.params.hasOwnProperty("id");

  useEffect(() => {
    if (hasParam) {
      const data = {
        ...props.location.state
      };
      setEditedData(data);
    }
  }, []);

  const submitFormHandler = (data, action) => {
    console.log("ACTION", action)
    if (action === "add") {
      console.log("Add Action", data)
      let apiData = {
        ...data
      };
      addBar(apiData).then(res => {
        if (res && res.status) {
          props.history.push("/bars");
        } else {
          if (res) {
            setMsg(res.message);
            showToast(true);
          }
        }
      });
    } else if (action === "edit") {
      console.log("Edit ACtion", data)
      let apiData = {
        ...data,
        id: props.location.state.id,
      };
      console.log("Edit ACtion API", apiData)
      editBar(apiData).then(res => {
        if (res && res.status) {
          props.history.push("/bars");
        } else {
          if (res) {
            setMsg(res.message);
            showToast(true);
          }
        }
      });
    }
  };


  return (
    <UserWrapper {...props}>
      {toast && (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className="c-danger"
          message={toastMsg}
          onCloseCLick={() => showToast(false)}
        />
      )}
      <div className="pos-relative">
        <PageTitle
          title="Venues"
          className="plr-15"
          iconClass="fas fa-glass-martini"
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/bars")}
        >
          <i className="fas fa-step-backward"></i> Backward
                </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span>{" "}
              {hasParam ? "Edit" : "Add"} Venues
                        </div>
          </div>
          <div className="roe-card-body">
            <BarForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              action={hasParam ? "edit" : "add"}
            />
          </div>
        </div>
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(
  mapStateToProps,
  null
)(BarEdit);
