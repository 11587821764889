import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    code: Yup.string().required("This field is required").matches(
            /^([A-Z0-9]+)$/,
            "Allow alphanumeric"
          ),
    name: Yup.string().required("This field is required"),
    //description: Yup.string().required("This field is required"),
    discount_amount: Yup.number().required("This field is required"),
    startsAt: Yup.string().required("This field is required"),
    expiresAt: Yup.string().required("This field is required")
  }),

  // isInitialValid: true,
  mapPropsToValues: props => ({
    code: props.editedData ? props.editedData.code : "",
    name: props.editedData ? props.editedData.name : "",
    description: props.editedData ? props.editedData.description : "",
    discount_amount: props.editedData ? props.editedData.discount_amount : "",
    startsAt: props.editedData ? props.editedData.startsAt : "",
    expiresAt: props.editedData ? props.editedData.expiresAt : ""
  }),
  handleSubmit: values => { },
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
