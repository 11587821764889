import React from "react";
import StyleWrapper from "../../../assets/styledComponents/user.style";
import PageTitle from "components/common/PageTitle";
import TemplateTable from "../../../components/template/Notification/TemplateTable";
import { connect } from "react-redux";

const Template = props => {
    console.log("this is testing", props);
    
    return (
        <StyleWrapper {...props}>
            <PageTitle title="Template" className="plr-15" iconClass="fas fa-paint-brush" />
            <div className="user-tbl">
                <TemplateTable {...props} />
            </div>
        </StyleWrapper>
    );
};

const mapStateToProps = state => {
    return {
        ...state.themeChanger
    };
};

export default connect(mapStateToProps, null)(Template);
