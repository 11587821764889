import React, { useState, useEffect, Fragment } from "react";
import ReactTable from "react-table-6";
import Button from "../../../components/button/Button"
import UserWrapper from "../../../assets/styledComponents/user.style";
import { connect } from "react-redux";
import {
  showDocuments,
} from "../../../apis/index";
const AWS_BAR_DOCUMENT_URL = process.env.REACT_APP_S3URL+'bardocuments/';
let debounceTimer;

const DocumentTable = (props) => {
  const [tblData, settblData] = useState([]);
  const [record, setRecord] = useState(true)
  const [loader, setLoader] = useState(true);
  const hasParam = props.match.params.hasOwnProperty("id");
  let id = null;
  useEffect(() => {
    if (hasParam) {
      id = props.match.params.id;
    }
  }, []);

  useEffect(() => {
    showDocuments(id).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        if(res.data.list.length === 0){
          setRecord(false)
        }
        console.log("documents data =>", res);
        console.log("record ", record)
        setLoader(false);
      }
    });
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
    };
  }, []);

  

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        className: "text-center",
        filterable: false,
        headerClassName: "react-table-header-class",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        filterable: false,
        Cell: (props) => (
          <div className="react-action-class">
            
            <Button
              className="c-btn c-success mr-10"
              onClick={(e) => {
                window.location.href=`${AWS_BAR_DOCUMENT_URL+props.original.name}`;
                }}
              
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-eye mr-6" />  View
              </div>
            </Button>
          
          </div>
        ),
      },
    ],
    [id]
  );

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/bars")}
        >
          <i className="fas fa-step-backward"></i> Backward
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Documents
            </div>
          </div>
          <div className="roe-card-body">
          <div className="roe-card-body">
            {record ? <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              page={0}
              data={tblData}
              columns={columns}
              minRows={0}
              showPaginationBottom={false}
              className="-striped -highlight custom-react-table-theme-class"
              loading={loader}
            /> : <h1>NO DOCUMENTS</h1>}
          
          </div>
        </div>
        </div>
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(mapStateToProps, null)(DocumentTable);
