import Dashboard from "views/Dashboard/dashboard";
import Users from "views/UserManagement/User";
import UserEdit from "views/UserManagement/UserEdit";
import Bars from 'views/BarManagement/Bar';
import BarEdit from 'views/BarManagement/BarEdit';
import Pages from 'views/PageManagement/pageManagement';
import PageEdit from 'views/PageManagement/PageEdit';
import Menu from 'views/BarManagement/MenuManagement/Menu';
import Menus from 'views/BarManagement/MenuManagement/MenusContainer';
import Document from "../views/BarManagement/DocumentManagement/Document";
import Categories from 'views/CategoryManagement/Category';
import CategoryEdit from 'views/CategoryManagement/CategoryEdit';
import PromoCode from 'views/PromoCodeManagement/PromoCode';
import PromoCodeEdit from 'views/PromoCodeManagement/PromoCodeEdit';
import EmailTemplate from 'views/TemplateManagement/Email/Template';
import EmailTemplateEdit from 'views/TemplateManagement/Email/TemplateEdit';
import NotificationTemplate from 'views/TemplateManagement/Notification/Template';
import NotificationTemplateEdit from 'views/TemplateManagement/Notification/TemplateEdit';
import Orders from 'views/OrderManagement/Orders';
import VenueOrder from 'views/OrderManagement/VenueOrder';
import UserPromo from "views/UserManagement/UserPromo";
import BarPromo from "views/BarManagement/BarPromo";
import Transactions from 'views/TransactionManagement/Transactions';
import UserOrders from '../views/UserManagement/Orders';

const dashboardRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/users", component: Users },
  { path: "/users/add", component: UserEdit },
  { path: "/users/edit/:id", component: UserEdit },
  { path: "/bars", component: Bars },
  { path: "/bars/add", component: BarEdit },
  { path: "/bars/edit/:id", component: BarEdit },
  { path: "/bars/menu/:id", component: Menu },
  { path: "/bars/category/:name", component: Menus },
  { path: "/bars/document/:id", component: Document },
  { path: "/pages", component: Pages },
  { path: "/pages/add", component: PageEdit },
  { path: "/pages/edit/:id", component: PageEdit },
  { path: "/categories", component: Categories },
  { path: "/category/add", component: CategoryEdit },
  { path: "/category/edit/:id", component: CategoryEdit },
  { path: "/promo-code", component: PromoCode },
  { path: "/promo-code/add", component: PromoCodeEdit },
  { path: "/promo-code/edit/:id", component: PromoCodeEdit },
  { path: "/template/email", component: EmailTemplate },
  { path: "/template/email/edit/:id", component: EmailTemplateEdit },
  { path: "/template/notification", component: NotificationTemplate },
  { path: "/template/notification/edit/:id", component: NotificationTemplateEdit },
  { path: "/venue-orders/order", component: Orders },
  { path: "/venue-orders", component: VenueOrder },
  { path: "/users/view-promo/:id", component: UserPromo },
  { path: "/bars/view-promo/:id", component: BarPromo },
  { path: "/transactions", component: Transactions },
  { path: "/users/orders", component: UserOrders },
];

export default dashboardRoutes;