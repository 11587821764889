import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import styled from "styled-components";
import { iconDemo } from "../../helper/constant";

const Wrapper = styled.div`
  .key {
    font-family: montserrat-bold;
    font-size: 16px;
    overflow-wrap: break-word;
  }
  .value {
    overflow-wrap: break-word;
  }
`;

const viewPromoCodeModal = props => {

  const { modal, toggle, promoCodeDetails } = props;
  return (
    <Wrapper>
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <div>
            <h6>
              <strong>View Promo Code</strong>
            </h6>
          </div>
        </ModalHeader>
        <ModalBody>
          <Wrapper>
            <div className="row my-1">
              <div className="col-5 text-right key">Id</div>
              <div className="col-7 text-left value">{promoCodeDetails.id}</div>
            </div>
            <div className="row">
              <div className="col-5 text-right key">Code</div>
              <div className="col-7 text-left value">
                {promoCodeDetails.code}
              </div>
            </div>
            <div className="row my-1">
              <div className="col-5 text-right key">Name</div>
              <div className="col-7 text-left value">
                {promoCodeDetails.name}
              </div>
            </div>
            <div className="row">
              <div className="col-5 text-right key">Discount(%)</div>
              <div className="col-7 text-left value">
                {promoCodeDetails.discount_amount}
              </div>
            </div>
            <div className="row my-1">
              <div className="col-5 text-right key">Start Date</div>
              <div className="col-7 text-left value">{`${new Date(promoCodeDetails.startsAt).getMonth() + 1}/${new Date(
                        promoCodeDetails.startsAt
                    ).getDate()}/${new Date(promoCodeDetails.startsAt).getFullYear()}`}</div>
            </div>
            <div className="row">
              <div className="col-5 text-right key">Expires Date</div>
              <div className="col-7 text-left value">{`${new Date(promoCodeDetails.expiresAt).getMonth() + 1}/${new Date(
                        promoCodeDetails.expiresAt
                    ).getDate()}/${new Date(promoCodeDetails.expiresAt).getFullYear()}`}</div>
            </div>
            <div className="row">
              <div className="col-5 text-right key">Status</div>
              <div className="col-7 text-left value">{promoCodeDetails.status}</div>
            </div>
            <div className="row">
              <div className="col-5 text-right key">Description</div>
              <div className="col-7 text-left value"><p dangerouslySetInnerHTML={{ __html: promoCodeDetails.description }}></p>
            </div>
            </div>
          </Wrapper>
        </ModalBody>
      </Modal>
    </Wrapper>
  );
};

export default viewPromoCodeModal;
