
import { LOGIN, LOGOUT } from './authActions'

const initState = {
    isLogin: localStorage.getItem('isLogin') ? localStorage.getItem('isLogin') === 'true' : false,
    accessToken: localStorage.getItem('accessToken')
}


export default function rootReducer(state = initState, action) {

    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isLogin: action.isLogin,
                accessToken: action.accessToken,
            }
        case LOGOUT:
            return {
                ...state,
                isLogin: action.isLogin,
                accessToken: null
            }
        default:
            return state
    }
}