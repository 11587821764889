import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import ReactTable from "react-table-6";
import { Tooltip } from "reactstrap";
import Button from "components/button/Button";
import RoyTooltip from "components/common/RoyTooltip";
import Pagination from "components/common/Pagination";
import ViewBarModal from "./viewBarModal";
import { iconDemo, pdfIcon } from "helper/constant";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import "react-table-6/react-table.css";
import swal from "sweetalert";
import { withRouter } from "react-router";
import {
  barList,
  showBarDetails,
  changeBarStatus,
  deleteBar,
  showDocuments,
  verifyAccount,
  changeBarEditorStatus,
  changeBarDocketStatus,
} from "../../apis/index";
import { getPagination } from "../../helper/constant";
import NumberFormat from "react-number-format";

let debounceTimer;
const AWS_BAR_URL = process.env.REACT_APP_S3URL + "bar/original/";
const AWS_BAR_DOCUMENT_URL = process.env.REACT_APP_S3URL + "bardocuments/";

const BarTable = (props) => {
  const history = useHistory();
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pages, setPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [params, setParams] = useState({
    isExport: false,
    orderBy: "venueEarnings",
    orderSequence: "DESC",
    pageNo: 1,
    pageSize: 25,
    search: "",
  });
  const [userDetails, setUserDetails] = useState({});
  const [transactionFee, setTransactionFee] = useState(null);
  const [modal, setModal] = useState(false);
  const [DocModal, setDocModal] = useState(false);
  const [barId, setBarId] = useState(null);
  const [doc, setDoc] = useState({
    id: 0,
    list: [],
  });

  const toggleDoc = (barid = null) => {
    setDocModal(!DocModal);
    setBarId(barid);
  };
  const toggle = () => setModal(!modal);

  const {
    sidebarTheme: { activeColor, textColor },
  } = props;

  useEffect(() => {
    if (searchText !== null) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
      debounceTimer = setTimeout(callSearchApi, 500);
    }
  }, [searchText]);

  useEffect(() => {
    barList(params).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = getPagination(res.data.totalRecords, params.pageSize);
        setPages(pages);
      }
    });
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
    };
  }, []);

  const convertArrayOfObjectsToCSV = (args) => {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data;
    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = ",";
    lineDelimiter = "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item) => {
      ctr = 0;
      keys.forEach(function(key) {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });
    return result;
  };

  const exportToCSV = () => {
    barList({
      isExport: true,
      orderBy: "venueEarnings",
      orderSequence: "DESC",
      pageNo: 1,
      pageSize: 100000,
      search: "",
    })
      .then((res) => {
        if (res && res.status) {
          var data, filename, link;

          var csv = convertArrayOfObjectsToCSV({
            data: res.data.list,
          });

          if (csv == null) return;

          filename = "users.csv";

          if (!csv.match(/^data:text\/csv/i)) {
            csv = "data:text/csv;charset=utf-8," + csv;
          }
          data = encodeURI(csv);

          link = document.createElement("a");
          link.setAttribute("href", data);
          link.setAttribute("download", filename);
          link.click();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserList = () => {
    // setLoader(true);
    barList(params).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = getPagination(res.data.totalRecords, params.pageSize);
        setPages(pages);
      }
    });
  };

  const onPageChangeHandler = (index) => {
    let page = index + 1;
    setActivePage(page);
    let data = {
      ...params,
      pageNo: page,
    };
    setParams(data);
    setLoader(true);
    barList(data).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = getPagination(res.data.totalRecords, params.pageSize);
        setPages(pages);
      }
    });
  };

  const callSearchApi = () => {
    const data = {
      ...params,
      search: searchText,
    };
    setParams(data);
    setLoader(true);
    barList(data).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = getPagination(res.data.totalRecords, params.pageSize);
        setPages(pages);
      }
    });
  };

  const activeInactiveStatusHandler = (id) => {
    setLoader(true);
    changeBarStatus(id).then((res) => {
      getUserList();
    });
  };

  const activeInactiveEditorStatusHandler = (id) => {
    setLoader(true);
    changeBarEditorStatus(id).then((res) => {
      getUserList();
    });
  };

  const enableDisableDocketHandler = (id) => {
    setLoader(true);
    changeBarDocketStatus(id).then((res) => {
      getUserList();
    });
  };

  const showOneUser = (data) => {
    showBarDetails(data).then((res) => {
      if (res && res.status) {
        setUserDetails(res.data);
        setTransactionFee(
          res.data.setting && res.data.setting.paramVal
            ? res.data.setting.paramVal
            : ""
        );
        setModal(true);
      } else {
      }
    });
  };

  const onSortChange = (sortProperties) => {
    const orderByDirection = sortProperties[0].desc ? 1 : 0;
    if (!orderByDirection) {
      let data = {
        ...params,
        orderBy: sortProperties[0].id,
        orderSequence: "DESC",
      };
      setParams(data);
      setLoader(true);
      barList(data).then((res) => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
          const pages = getPagination(res.data.totalRecords, params.pageSize);
          setPages(pages);
        }
      });
    } else {
      let data = {
        ...params,
        orderSequence: "ASC",
      };
      setParams(data);
      setLoader(true);
      barList(data).then((res) => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
          const pages = getPagination(res.data.totalRecords, params.pageSize);
          setPages(pages);
        }
      });
    }
  };

  const formAction = (action, data = null) => {
    if (action === "add") {
      props.history.push("/bars/add");
    } else if (action === "edit") {
      props.history.push(`/bars/edit/${data.id}`, {
        ...data,
      });
    } else if (action === "view-promo") {
      props.history.push(`/bars/view-promo/${data.id}`, {
        ...data,
      });
    }
  };

  const viewMenu = (id) => {
    props.history.push(`/bars/menu/${id}`);
  };

  const deletePage = (pageId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteBar(pageId).then((res) => {
          if (res && res.status) {
            swal(res.message, {
              icon: "success",
            });
            setLoader(true);
            barList(params).then((res) => {
              if (res && res.status) {
                settblData(res.data.list);
                setLoader(false);
                const pages = getPagination(
                  res.data.totalRecords,
                  params.pageSize
                );
                setPages(pages);
              }
              if (res && !res.data.list.length) {
                onPageChangeHandler(params.pageNo - 1);
              }
            });
            getUserList();
          } else {
            if (res) {
              swal(res.message, {
                icon: "danger",
              });
            }
          }
        });
      }
    });
  };

  const viewDocumentImg = (id) => {
    history.push(`/bars/document/${id}`);
  };

  const verifyAccountDoc = (status, id) => {
    verifyAccount({
      isVerified: status,
      id: id,
    }).then((res) => {
      if (res && res.status) {
        toggleDoc();
        getUserList();
      }
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Restaurant",
        // accessor: "avatar",
        className: "text-center",
        headerClassName: "react-table-header-class ",
        filterable: false,
        sortable: false,
        width: 210,
        Cell: (props) => (
          <div
            className="react-action-class"
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            <img
              className="mx-2"
              height="30px"
              src={
                props.original.avatar ? `${props.original.avatar}` : iconDemo
              }
              alt="profile"
            />
            <p style={{ whiteSpace: "pre-wrap", textAlign: "initial" }}>
              {props.original.restaurantName}
            </p>
          </div>
        ),
      },
      // {
      //     Header: "Restaurant",
      //     accessor: "restaurantName",
      //     className: "text-center",
      //     filterable: false,
      //     sortable: false,
      //     headerClassName: "react-table-header-class"
      // },
      {
        Header: "Manager",
        accessor: "managerName",
        className: "text-center",
        filterable: false,
        sortable: false,
        width: 150,
        headerClassName: "react-table-header-class",
      },
      {
        Header: "Account Verification",
        accessor: "accountVerified",
        className: "text-center",
        headerClassName: "react-table-header-class",
        filterable: false,
        width: 200,
        Cell: (props) => (
          <div className="react-action-class">
            {props.original.accountVerified === "New" ? (
              <Fragment>
                <div id={`verified-${props.original.id}`}>
                  <Button
                    style={{ minWidth: "125px" }}
                    className="c-btn c-info mr-10"
                    onClick={() => toggleDoc(props.original.id)}
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-hourglass-half mr-6"></i> Pending
                    </div>
                  </Button>
                </div>
                {/* <RoyTooltip
                                    id={`verified-${props.original.id}`}
                                    title={"Click to View Document"}
                                    placement="left"></RoyTooltip> */}
              </Fragment>
            ) : props.original.accountVerified === "Approved" ? (
              <Fragment>
                <div>
                  <Button
                    style={{ minWidth: "125px" }}
                    className="c-btn disabled c-success mr-10"
                    // onClick={() =>
                    //     activeInactiveStatusHandler(props.original.id)
                    // }
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-check-circle mr-6" /> Approved
                    </div>
                  </Button>
                </div>
              </Fragment>
            ) : (
              <div>
                <Button
                  style={{ minWidth: "125px" }}
                  className="c-btn disabled c-danger mr-10"
                  // onClick={() =>
                  //     activeInactiveStatusHandler(props.original.id)
                  // }
                >
                  <div className="fs-14 medium-text">
                    <i className="fas fa-ban mr-6" /> Rejected
                  </div>
                </Button>
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        className: "text-center",
        headerClassName: "react-table-header-class",
        filterable: false,
        width: 140,
        Cell: (props) => (
          <div className="react-action-class">
            {props.original.status === "Active" ? (
              <Fragment>
                <div id={`active-${props.original.id}`}>
                  <Button
                    style={{ minWidth: "125px" }}
                    className="c-btn c-info mr-10"
                    onClick={() =>
                      activeInactiveStatusHandler(props.original.id)
                    }
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-off mr-6" /> Active
                    </div>
                  </Button>
                </div>
                <RoyTooltip
                  id={`active-${props.original.id}`}
                  title={"Click to Deactivate"}
                  placement="left"
                ></RoyTooltip>
              </Fragment>
            ) : (
              <RoyTooltip
                id={`deactive-${props.original.id}`}
                title={"Click to Active"}
                placement="left"
              >
                <div id={`deactive-${props.original.id}`}>
                  <Button
                    style={{ minWidth: "125px" }}
                    className="c-btn c-warning mr-10"
                    onClick={() =>
                      activeInactiveStatusHandler(props.original.id)
                    }
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-on mr-6" /> InActive
                    </div>
                  </Button>
                </div>
              </RoyTooltip>
            )}
          </div>
        ),
      },
      {
        Header: "Editor Status",
        accessor: "editor status",
        className: "text-center",
        headerClassName: "react-table-header-class",
        filterable: false,
        sortable: false,
        width: 140,
        Cell: (props) => (
          <div className="react-action-class">
            {props.original.editorFlag === "Active" ? (
              <Fragment>
                <div id={`editor-${props.original.id}`}>
                  <Button
                    style={{ minWidth: "125px", backgroundColor: "#f4a261" }}
                    className="c-btn  mr-10"
                    onClick={() =>
                      activeInactiveEditorStatusHandler(props.original.id)
                    }
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-off mr-6" /> Active
                    </div>
                  </Button>
                </div>
                <RoyTooltip
                  id={`editor-${props.original.id}`}
                  title={"Click to Deactivate"}
                  placement="left"
                ></RoyTooltip>
              </Fragment>
            ) : (
              <RoyTooltip
                id={`editorDeactive-${props.original.id}`}
                title={"Click to Active"}
                placement="left"
              >
                <div id={`editorDeactive-${props.original.id}`}>
                  <Button
                    style={{
                      minWidth: "125px",
                      backgroundColor: "#ef476f",
                      color: "#fff",
                    }}
                    className="c-btn mr-10"
                    onClick={() =>
                      activeInactiveEditorStatusHandler(props.original.id)
                    }
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-on mr-6" /> InActive
                    </div>
                  </Button>
                </div>
              </RoyTooltip>
            )}
          </div>
        ),
      },
      {
        Header: "Docket Printing",
        accessor: "docketSubscribed",
        className: "text-center",
        headerClassName: "react-table-header-class",
        filterable: false,
        width: 140,
        Cell: (props) => (
          <div className="react-action-class">
            {props.original.docketSubscribed === "0" ? (
              <Fragment>
                <div id={`enable-${props.original.id}`}>
                  <Button
                    style={{
                      minWidth: "125px",
                      backgroundColor: "#40916c",
                      color: "#fff",
                    }}
                    className="c-btn mr-10"
                    onClick={() =>
                      enableDisableDocketHandler(props.original.id)
                    }
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-off mr-6" /> Enable
                    </div>
                  </Button>
                </div>
                <RoyTooltip
                  id={`enable-${props.original.id}`}
                  title={"Click to Disable"}
                  placement="left"
                ></RoyTooltip>
              </Fragment>
            ) : (
              <RoyTooltip
                id={`disable-${props.original.id}`}
                title={"Click to Enable"}
                placement="left"
              >
                <div id={`disable-${props.original.id}`}>
                  <Button
                    style={{ minWidth: "125px", backgroundColor: "#9e2a2b" }}
                    className="c-btn mr-10"
                    onClick={() =>
                      enableDisableDocketHandler(props.original.id)
                    }
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-on mr-6" /> Disable
                    </div>
                  </Button>
                </div>
              </RoyTooltip>
            )}
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        filterable: false,
        width: 600,
        Cell: (props) => (
          <div className="react-action-class">
            <Button
              className="c-btn c-success mr-10"
              onClick={() => viewDocumentImg(props.original.id)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-file mr-6"></i>Document
              </div>
            </Button>
            {/* <Button
                            className="c-btn c-success mr-10"
                            onClick={() => viewDocumentImg(props.original.id)}>
                            <div className="fs-14 medium-text">
                                <i className="fas fa-eye mr-6" /> View Document
                            </div>
                        </Button> */}

            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction("view-promo", props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-percentage mr-6" />
                Promocode
              </div>
            </Button>
            <Button
              className="c-btn mr-10"
              style={{ backgroundColor: "rgb(0, 119, 182)", color: "#fff" }}
              // onClick={() => viewMenu(props.original.id)}>
              onClick={() => viewMenu(props.original.id)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-bars mr-6"></i> Category
              </div>
            </Button>
            <Button
              className="c-btn c-success mr-10"
              onClick={() => showOneUser(props.original.id)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-eye" />
              </div>
            </Button>
            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction("edit", props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
            <Button
              className="c-btn c-danger"
              onClick={() => deletePage(props.original.id)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-trash" />
              </div>
            </Button>
          </div>
        ),
      },
      {
        Header: "Total Revenue",
        accessor: "venueEarnings",
        className: "text-center",
        filterable: false,
        sortable: false,
        width: 150,
        headerClassName: "react-table-header-class",
        Cell: (props) => (
          <NumberFormat
            style={{ letterSpacing: "1px", fontWeight: "600" }}
            value={props.original.venueEarnings}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            fixedDecimalScale={true}
            decimalScale={2}
          />
        ),
      },
      {
        Header: "Created Date",
        accessor: "createdAt",
        className: "text-center",
        filterable: false,
        sortable: true,
        width: 130,
        headerClassName: "react-table-header-class -sort",
        Cell: (props) => {
          return `${new Date(props.original.createdAt).getDate()}/${new Date(
            props.original.createdAt
          ).getMonth() + 1}/${new Date(
            props.original.createdAt
          ).getFullYear()}`;
        },
      },
    ],
    [params]
  );

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={DocModal} toggle={toggleDoc}>
        <ModalHeader toggle={toggleDoc}>Verify Document</ModalHeader>
        <ModalBody>
          <div className="doc-container">
            {!doc.list.length && <h6>No Documents to show</h6>}
            {doc.list.map((data, index) => {
              if (data) {
                let blackClass = "image-block";
                let imageUrl,
                  targetURL = `${AWS_BAR_DOCUMENT_URL}${data.name}`;
                if (data.name.split(".").pop() === "pdf") {
                  blackClass = "pdf-block";
                  imageUrl = pdfIcon;
                }

                return (
                  <div className={blackClass} key={index}>
                    <a target="_black" href={targetURL}>
                      <img width="100%" src={imageUrl} alt="doc" />
                    </a>
                  </div>
                );
              }
              return null;
              //if(filename.split('.').pop())
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="c-btn c-danger mr-10"
            onClick={() => verifyAccountDoc(false, barId)}
          >
            Reject
          </Button>
          <Button
            className="c-btn c-success mr-10"
            onClick={() => verifyAccountDoc(true, barId)}
          >
            Approve
          </Button>
        </ModalFooter>
      </Modal>
      <ViewBarModal
        userDetails={userDetails}
        transactionFee={transactionFee}
        toggle={toggle}
        modal={modal}
      />
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Venues
            </div>
            <div className="mr-10">
              <input
                value={searchText ? searchText : ""}
                onChange={(e) => setSearchText(e.target.value)}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            <div>
              <Button
                className="c-btn ma-5"
                onClick={() => formAction("add")}
                style={{
                  backgroundColor: activeColor,
                  color: textColor,
                }}
              >
                <i className="fas fa-plus mr-10"></i>Add Bar
              </Button>
            </div>
            <div>
              <Button
                className="c-btn ma-5 c-warning"
                onClick={() => exportToCSV()}
                style={{
                  backgroundColor: activeColor,
                  color: textColor,
                }}
              >
                <i className="fas fa-file-export mr-10"></i>Export
              </Button>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={2}
              minRows={0}
              pages={pages}
              filterable
              manual
              className="-striped -highlight custom-react-table-theme-class"
              page={activePage - 1}
              PaginationComponent={Pagination}
              loading={loader}
              onPageChange={(pageIndex) => onPageChangeHandler(pageIndex)}
              onSortedChange={(sortProperties) => onSortChange(sortProperties)}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BarTable);
